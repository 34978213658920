/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-empty */
/**
 * Main application knowledge
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import MDTypography from "components/Basics/MDTypography";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Icon,
	IconButton,
	Menu,
	MenuItem,
	Tooltip
} from "@mui/material";
import i18n from "i18n";
import lod_ from "lodash";
import { read, utils } from "xlsx";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import ConfirmDialog from "components/Custom/Dialogs/ConfirmDialog";
import AddKnwoledgeDialog from "./AddKnowledgeDialog";
import EditKnowledgeDialog from "./EditKnwoledgeDialog";
import ChartsActions from "redux-react/actions/chartsActions";
import FaiqActions from "redux-react/actions/faiqActions";
import { display, hide } from "redux-react/reducers/snackBarReducer";
import { useNavigate } from "react-router-dom";
import { addNotification } from "redux-react/reducers/notificationsReducer";
import { socket } from "redux-react/middleware/ws";
import MDBadge from "components/Basics/MDBadge";

const ImportResults = ({ open, list, handleClose }) => {
	const [inserted, setInserted] = useState([]);
	const [duplicates, setDuplicates] = useState([]);

	useEffect(() => {
		if (list?.inserted) {
			setInserted(list.inserted);
		}

		if (list.duplicates) {
			setDuplicates(list.duplicates);
		}
	}, [open === true]);

	return (
		<Dialog fullWidth maxWidth="xxl" open={open} onClose={() => handleClose()}>
			<DialogTitle>{i18n.t("KNOWLEDGE.IMPORT.importDetail")}</DialogTitle>
			<DialogContent>
				<MDTypography variant="h6">{`${i18n.t("KNOWLEDGE.IMPORT.importedKnowledge")} (${
					inserted.length
				})`}</MDTypography>
				{inserted.map((item, index) => {
					let keys = Object.keys(item);
					return (
						<table className="resultsImportTable">
							<tr>
								{keys.map((key, index) => {
									return (
										<th key={index} className={key === "content" ? "importFullCell" : ""}>
											{key}
										</th>
									);
								})}
							</tr>
							<tr>
								{keys.map((key, index) => {
									return <td key={index}>{item[key] ?? "-"}</td>;
								})}
							</tr>
						</table>
					);
				})}
				<MDTypography variant="h6" mt={3}>{`${i18n.t("KNOWLEDGE.IMPORT.unimportedKnowledge")} (${
					duplicates.length
				})`}</MDTypography>
				{duplicates.map((item, index) => {
					let keys = Object.keys(item);
					return (
						<table className="resultsImportTable">
							<tr>
								{keys.map((key, index) => {
									return (
										<th key={index} className={key === "content" ? "importFullCell" : ""}>
											{key}
										</th>
									);
								})}
							</tr>
							<tr>
								{keys.map((key, index) => {
									return <td key={index}>{item[key] ?? "-"}</td>;
								})}
							</tr>
						</table>
					);
				})}
			</DialogContent>
			<DialogActions>
				<MDButton variant="contained" color="dark" onClick={handleClose}>
					{i18n.t("SETTINGS.close")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};

/**
 * Main component
 */
export default function KnowledgePage({ route }) {
	const { profile, filters } = useSelector(state => state);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// Main chart
	const [rawCharts, setRawCharts] = useState({});
	const [pageFilters, setPageFilters] = useState([]);
	const [paginedList, setPaginedList] = useState(null);

	const [reloadTable, setReloadTable] = useState(false);
	// Count of data need to be updated
	const [countNotUpToDate, setCountNotUpToDate] = useState(0);
	const [stats, setStats] = useState(null);
	// Add new knowledge dialog
	const [addNewKnowledge, setAddNewKnowledge] = useState(false);
	// Edit knowledge dialog
	const [editKnwoledge, setEditKnowledge] = useState({ open: false, knowledge: {} });
	// Delete confirm dialog
	const [confirmDialog, setConfirmDialog] = useState({
		open: false,
		title: "",
		content: "",
		handleConfirm: () => {}
	});
	// Import results dialog
	const [importResults, setImportResults] = useState({
		open: false,
		list: []
	});

	const [menuRef, setMenuRef] = useState(null);
	const [menuValues, setMenuValues] = useState({});
	const [actionsAnchorEl, setActionsAnchorEl] = useState(null);

	const [selectedRows, setSelectedRows] = useState([]);

	const isTokensConsumptionExceeded = stats => {
		return stats?.tokensConsumption >= stats?.tokensConsumptionLimit;
	};

	/* Load chart */
	function loadPaginedList() {
		const onSuccess = res => {
			setPageFilters(res.filters);
			setRawCharts(res.charts);
			setReloadTable(!reloadTable);
		};

		dispatch(ChartsActions.getCharts(profile.assistantID, route, onSuccess));
	}
	/* Build charts */
	function buildRawCharts() {
		if (lod_.isEmpty(rawCharts)) return;
		// Success handler : set charts data
		const onSuccess = res => {
			setPaginedList(res.charts[0]);
			setCountNotUpToDate(res.charts[0].editedCount);
			setStats(res.charts[0].stats);
		};
		// Build charts
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);
		dispatch(ChartsActions.buildRawCharts(rawCharts, filters, mandatoryFilters, onSuccess));
	}
	/* Handle add dialog */
	const addKnowledgeHandle = knowledge => {
		const onSuccess = res => {
			dispatch(
				display({
					type: "success",
					message: i18n.t("KNOWLEDGE.SUCCESS.add")
				})
			);
			setReloadTable(!reloadTable);
			setCountNotUpToDate(res.editedCount);
		};
		dispatch(FaiqActions.saveKnowledge(profile.assistantID, knowledge, onSuccess));
	};
	/* Handle edit dialog */
	const editKnowledgeHandle = update => {
		const onSuccess = res => {
			dispatch(
				display({
					type: "success",
					message: i18n.t("KNOWLEDGE.SUCCESS.edit")
				})
			);
			setReloadTable(!reloadTable);
			setCountNotUpToDate(res.editedCount);
		};
		dispatch(FaiqActions.updateKnowledge(profile.assistantID, update, onSuccess));
	};
	/* Handle delete confirm dialog */
	const actionDeleteHandle = ({ values }) => {
		let { content, metadatas, uuid } = values;
		const title = metadatas?.title || uuid;
		const handleConfirmDialog = () => {
			setConfirmDialog({
				open: false
			});
			const onSuccess = res => {
				dispatch(
					display({
						message: i18n.t("SETTINGS.LABELS.SUCCESS.delete"),
						type: "success"
					})
				);
				setReloadTable(!reloadTable);
			};
			dispatch(FaiqActions.deleteKnowledge(profile.assistantID, content, onSuccess));
		};

		setConfirmDialog({
			open: true,
			title: `${i18n.t("SETTINGS.delete")} ${i18n.t("KNOWLEDGE.theKnowledge")} "${title}"`,
			content: `${i18n.t("SETTINGS.deleteConfirmation")} ${i18n.t(
				"KNOWLEDGE.theKnowledge"
			)} "${title}" ?`,
			handleConfirm: handleConfirmDialog
		});
	};
	/* Train knowledge */
	const trainKnowledge = ({ trainAll, content }) => {
		if (isTokensConsumptionExceeded(stats)) {
			return;
		}

		const onSuccess = res => {
			dispatch(
				addNotification({
					assistantID: profile.assistantID,
					type: "info",
					message: "Entrainement en cours...",
					ts: new Date().getTime()
				})
			);
		};

		dispatch(
			FaiqActions.faiqTrain(
				profile.assistantID,
				{
					trainAll,
					content
				},
				onSuccess
			)
		);
	};

	const handleImportFile = event => {
		const POSSIBLE_TITLES = ["title", "titre"];
		const POSSIBLE_CONTENTS = ["content", "contenu"];
		const POSSIBLE_URLS = ["url", "lien", "source", "site"];
		const POSSIBLE_PROJECTS = ["project", "projet"];

		let imports = [];

		try {
			let file = event.target.files[0];
			const reader = new FileReader();
			// 1- Need to read the file
			reader.onload = e => {
				const wb = read(e.target.result);
				const sheets = wb.SheetNames;
				const data = utils.sheet_to_json(wb.Sheets[sheets[0]]);

				// 2- Need to parse the data
				for (let row of data) {
					let knowledge = {
						title: null,
						content: null,
						url: null,
						project: null
					};

					Object.keys(row).map(key => {
						let value = row[key];

						if (POSSIBLE_TITLES.includes(key.toLowerCase())) {
							knowledge.title = value;
						}

						if (POSSIBLE_CONTENTS.includes(key.toLowerCase())) {
							knowledge.content = value;
						}

						if (POSSIBLE_URLS.includes(key.toLowerCase())) {
							knowledge.url = value;
						}

						if (POSSIBLE_PROJECTS.includes(key.toLowerCase())) {
							knowledge.project = value;
						}
					});

					imports.push(knowledge);
				}

				// 3- Filter empty knowledge
				imports = imports.filter(knowledge => knowledge.content);

				// 4- Check if knowledge already exists in database

				const onSuccessCheck = ({ inserted, duplicates }) => {
					dispatch(
						display({
							type: "info",
							message: `${inserted.length} ${i18n.t(
								"KNOWLEDGE.IMPORT.displayImportedKnowledge"
							)}, ${duplicates.length} ${i18n.t("KNOWLEDGE.IMPORT.displayDuplicate")}`,
							validation: (
								<>
									<MDButton
										sx={{ ml: 1 }}
										variant="contained"
										component="label"
										color="info"
										onClick={() => {
											dispatch(hide());
											setImportResults({
												open: true,
												list: {
													inserted,
													duplicates
												}
											});
										}}
									>
										Plus de détails
									</MDButton>
									<MDButton
										sx={{ ml: 1 }}
										onClick={() => {
											dispatch(hide());
										}}
									>
										{i18n.t("SETTINGS.close")}
									</MDButton>
								</>
							)
						})
					);

					setReloadTable(!reloadTable);
				};

				if (imports.length > 0) {
					dispatch(
						FaiqActions.checkExistingKnowledgeList(
							profile.assistantID,
							{
								list: imports
							},
							onSuccessCheck
						)
					);
				} else {
					dispatch(
						display({
							type: "warning",
							message: i18n.t("KNOWLEDGE.IMPORT.errorImport"),
							validation: (
								<MDButton
									sx={{ ml: 1 }}
									onClick={() => {
										dispatch(hide());
									}}
								>
									{i18n.t("SETTINGS.close")}
								</MDButton>
							)
						})
					);
				}
			};
			reader.readAsArrayBuffer(file);

			event.target.value = null;
		} catch (error) {}
	};

	const getTrainingText = (countNotUpToDate, stats) => {
		if (isTokensConsumptionExceeded(stats)) {
			return (
				<MDTypography mt={0.5} variant="caption">
					{`${i18n.t("KNOWLEDGE.TRAIN.trainingTokensConsumptionExceeded")} (${
						stats?.tokensConsumption
					} / ${stats?.tokensConsumptionLimit})`}
				</MDTypography>
			);
		}

		if (countNotUpToDate < 1) {
			return (
				<MDTypography mt={0.5} variant="caption">
					{i18n.t("KNOWLEDGE.TRAIN.musntBeTrain")}
				</MDTypography>
			);
		} else {
			return (
				<MDTypography mt={0.5} variant="caption">{`${countNotUpToDate} ${i18n.t(
					"KNOWLEDGE.TRAIN.mustBeTrain"
				)}`}</MDTypography>
			);
		}
	};

	/**
	 * Delete many knowledges
	 * @param {*} knowledges - Knowledges to delete
	 */
	const actionDeleteManyKnowledges = knowledges => {
		const handleConfirmDialog = async () => {
			setConfirmDialog({
				open: false
			});

			const onSuccess = res => {
				dispatch(
					display({
						message: "Connaisances supprimées avec succès",
						type: "success"
					})
				);
				setReloadTable(!reloadTable);
			};
			// Delete source in database
			setActionsAnchorEl(null);
			setSelectedRows([]);
			dispatch(FaiqActions.deleteKnowledges(profile.assistantID, knowledges, null, onSuccess));
		};

		setConfirmDialog({
			open: true,
			title: `${i18n.t("SETTINGS.delete")} ${knowledges.length} ${i18n.t("KNOWLEDGE.knowledges")}`,
			content: `${i18n.t("SETTINGS.deleteConfirmation")} ${knowledges.length} ${i18n.t("KNOWLEDGE.knowledges")} ?`,
			handleConfirm: handleConfirmDialog
		});
	};

	useEffect(() => {
		buildRawCharts();
	}, [rawCharts]);

	useEffect(() => {
		setPaginedList(null);
		loadPaginedList();
	}, [profile.selectedAssistant.assistantID, route]);

	// WS event
	function knowledgeTrainResult({ success, message }) {
		let translatedtext = i18n.exists(`WS.MESSAGE.${message}`)
			? i18n.t(`WS.MESSAGE.${message}`)
			: message;

		dispatch(
			addNotification({
				assistantID: profile.assistantID,
				type: success ? "success" : "error",
				message: translatedtext,
				ts: new Date().getTime(),
				icon: success ? "check" : "close"
			})
		);

		setReloadTable(prev => !prev);
	}

	useEffect(() => {
		socket.on("knowledge_train_result", knowledgeTrainResult);

		return () => {
			socket.off("knowledge_train_result", knowledgeTrainResult);
		};
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar
				filters={[
					<MDBox
						display="flex"
						justifyContent="space-between"
						style={{
							width: "100%"
						}}
					>
						{/* First container */}
						<MDBox display="flex">
							{parseFilters(profile.assistantID, route.route, pageFilters, filters, dispatch)}
							<MDButton
								style={{ height: "100%", marginRight: "0.75rem" }}
								variant="contained"
								color="info"
								onClick={() => setAddNewKnowledge(true)}
							>
								<Icon>create</Icon>&nbsp;{i18n.t("SETTINGS.add")}
							</MDButton>
							<MDButton
								style={{ height: "100%" }}
								variant="contained"
								component="label"
								color="info"
							>
								<input
									type="file"
									name="file"
									accept={[
										".xls",
										".xlsx",
										".xlsm",
										".xlsb",
										".xlt",
										".xltx",
										".xltm",
										".xlam",
										".ods",
										".csv"
									]}
									hidden
									onChange={handleImportFile}
								></input>
								<Icon>publish</Icon>&nbsp;{i18n.t("SETTINGS.import")}
							</MDButton>
						</MDBox>
						{/* Second container */}
						<MDBox display="flex" flexDirection="row">
							<MDBox sx={{ mr: 2 }}>
								<MDBadge color="error" badgeContent={selectedRows.length} circular size="xs">
									<MDButton
										color="info"
										onClick={e => {
											// setAnchorEl(e.currentTarget);
											setActionsAnchorEl(e.currentTarget);
										}}
										disabled={selectedRows.length === 0}
									>
										<Icon>list_alt</Icon>&nbsp;Actions
									</MDButton>

									<Menu
										id="simple-actions-menu"
										anchorEl={actionsAnchorEl}
										open={Boolean(actionsAnchorEl)}
										onClose={e => {
											setActionsAnchorEl(null);
											e.stopPropagation();
											e.preventDefault();
										}}
									>
										{/* Options */}
										<MenuItem disabled style={{ opacity: 1 }}>
											<MDTypography variant="caption">
												{i18n.t(`FAIQ.TESTING.edition`)}
											</MDTypography>
										</MenuItem>
										<MenuItem
											onClick={() => {
												const knowledges = selectedRows.map(row => row.uuid);
												actionDeleteManyKnowledges(knowledges);
											}}
											style={{
												color: "red"
											}}
										>
											<Icon fontSize="medium">delete</Icon>&nbsp;Supprimer
										</MenuItem>
									</Menu>
								</MDBadge>
							</MDBox>
							<MDBox display="flex" flexDirection="column">
								<MDButton
									disabled={countNotUpToDate < 1 || isTokensConsumptionExceeded(stats)}
									variant="contained"
									color="error"
									onClick={() => {
										trainKnowledge({
											trainAll: true
										});
									}}
								>
									<Icon>replay</Icon>&nbsp;{i18n.t("SETTINGS.training")}
								</MDButton>
								{getTrainingText(countNotUpToDate, stats)}
							</MDBox>
						</MDBox>
					</MDBox>
				]}
			/>
			<Divider />
			{paginedList && (
				<DefaultDataTable
					checkbox
					selectedRows={selectedRows}
					handleSelection={values => {
						setSelectedRows(values);
					}}
					isSelectedRow={row => {
						const existingRow = selectedRows.find(r => r.uuid === row.uuid);
						return Boolean(existingRow);
					}}
					dictionary={paginedList.dictionary}
					canSearch
					table={paginedList.data}
					display={paginedList.request.attributesDisplay}
					pagination={paginedList.pagination}
					list={paginedList}
					optionsAction="left"
					actions={[
						<IconButton
							handleclick={({ values }, e) => {
								setMenuValues(values);
								setMenuRef(e.currentTarget);
								e.preventDefault();
								e.stopPropagation();
							}}
						>
							<Icon fontSize="medium">more_vert</Icon>
						</IconButton>
					]}
					reloadTable={reloadTable}
					filters={getLocalStorageBackValues(
						profile.selectedAssistant.assistantID,
						route.route,
						filters
					)}
				/>
			)}
			{/* Menu actions selection */}
			<Menu
				open={Boolean(menuRef)}
				anchorEl={menuRef}
				onClose={() => {
					setMenuRef(null);
				}}
			>
				<MenuItem disabled style={{ opacity: 1 }}>
					<MDTypography variant="caption">{i18n.t(`FAIQ.TESTING.edition`)}</MDTypography>
				</MenuItem>
				<MenuItem
					onClick={() => {
						setEditKnowledge({
							open: true,
							knowledge: menuValues
						});
					}}
				>
					<Icon fontSize="medium">edit</Icon>&nbsp;{i18n.t("SETTINGS.edit")}
				</MenuItem>
				<MenuItem
					onClick={() => {
						actionDeleteHandle({ values: menuValues });
					}}
				>
					<Icon fontSize="medium">delete</Icon>&nbsp;{i18n.t("SETTINGS.delete")}
				</MenuItem>
				<MenuItem
					onClick={() => {
						navigate(`/history?knowledge=${menuValues.uuid}`);
					}}
				>
					<Icon fontSize="medium">chat</Icon>&nbsp;{`Voir les messages liés\nà cette connaissance`}
				</MenuItem>
				{/*  */}
				<Divider />
				<MenuItem disabled style={{ opacity: 1 }}>
					<MDTypography variant="caption">{i18n.t(`FAIQ.TESTING.actions`)}</MDTypography>
				</MenuItem>
				<MenuItem
					disabled={!menuValues.edited || isTokensConsumptionExceeded(stats)}
					onClick={() => {
						trainKnowledge({
							trainAll: false,
							content: menuValues.content
						});
					}}
					style={{
						color: "red"
					}}
				>
					<Icon fontSize="medium">replay</Icon>&nbsp;Entrainer la connaissance
				</MenuItem>
			</Menu>
			{paginedList && (
				<AddKnwoledgeDialog
					open={addNewKnowledge}
					handleCloseDialog={() => setAddNewKnowledge(false)}
					handleSave={addKnowledgeHandle}
				/>
			)}
			{paginedList && editKnwoledge.open && (
				<EditKnowledgeDialog
					open={editKnwoledge.open}
					handleCloseDialog={() => setEditKnowledge({ open: false })}
					handleSave={editKnowledgeHandle}
					knowledge={editKnwoledge.knowledge}
					metadatasDictionary={paginedList.dictionary.metadatas}
				/>
			)}
			<ConfirmDialog
				open={confirmDialog.open}
				title={confirmDialog.title}
				content={confirmDialog.content}
				handleClose={() => {
					setConfirmDialog({
						open: false
					});
				}}
				handleConfirm={confirmDialog.handleConfirm}
			/>

			<ImportResults
				open={importResults.open}
				list={importResults.list}
				handleClose={() => {
					setImportResults({
						open: false,
						list: []
					});
				}}
			/>
			<MDBox mt={7}></MDBox>
		</DashboardLayout>
	);
}
