/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-cycle */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import lod_ from "lodash";
import FormActions from "redux-react/actions/formAction";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";

import InputBoolean from "./Inputs/InputBoolean";
import InputDelay from "./Inputs/InputDelay";
import InputPhone from "./Inputs/InputPhone";
import InputDate from "./Inputs/InputDate";
import InputEmail from "./Inputs/InputEmail";
import InputString from "./Inputs/InputString";
import InputNumber from "./Inputs/InputNumber";
import InputCode from "./Inputs/InputCode";
import InputImageList from "./Inputs/InputImageList";
import InputCron from "./Inputs/InputCron";
import InputWebhook from "./Inputs/InputWebhook";
import InputLabel from "./Inputs/InputLabel";
import InputActions from "./Inputs/InputActions";

/**
 * Component for input
 */
export const FormInput = ({
	item,
	path,
	datas,
	handleChange,
	regexEmail = "",
	listOfApis = [],
	errorUniqueFields
}) => {
	let type = item.type;
	let labelKeys = Object.keys(item.label);
	let label = (item.label ?? {})[labelKeys[0]];
	let codes = item.codes || [];

	let value = lod_.get(datas, path);
	let disabled = !(item?.actions?.edit ?? true);
	let error = errorUniqueFields?.includes(path);

	const onChange = value => {
		handleChange(path, value);
	};

	// Add a star to the label if the field is required
	if (item.isRequired) {
		label = (
			<span>
				{label}
				<span className="mandatoryField">*</span>
			</span>
		);
	}

	switch (type) {
		case "labels":
			return (
				<InputLabel
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					item={item}
					listOfApis={listOfApis}
					path={path}
				/>
			);
		case "cron":
			return <InputCron label={label} item={item} />;
		case "webhook":
			return <InputWebhook label={label} value={value} onChange={onChange} item={item} />;
		case "imageList":
			return (
				<InputImageList
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					item={item}
					listOfApis={listOfApis}
					path={path}
				/>
			);
		case "boolean":
			return (
				<InputBoolean
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
				/>
			);
		case "phone":
			return (
				<InputPhone
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
				/>
			);
		case "date":
		case "datetime":
			return (
				<InputDate
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
				/>
			);
		case "email":
			return (
				<InputEmail
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					regexEmail={regexEmail}
					item={item}
					listOfApis={listOfApis}
					path={path}
				/>
			);
		case "string":
			return (
				<InputString
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					item={item}
					listOfApis={listOfApis}
					path={path}
				/>
			);
		case "number":
			return (
				<InputNumber
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					item={item}
					listOfApis={listOfApis}
					path={path}
				/>
			);
		case "delay":
			return (
				<InputDelay
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
				/>
			);
		case "code":
			return (
				<InputCode
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					codes={codes}
					path={path}
				/>
			);
		case "actions":
			return (
				<InputActions
					disabled={disabled}
					error={error}
					label={label}
					value={value}
					onChange={onChange}
					item={item}
					listOfApis={listOfApis}
					path={path}
				/>
			);
		default:
			return (
				<MDBox mt={1}>
					<MDInput
						disabled={disabled}
						error={error}
						type={type}
						className="dialogInput"
						label={label}
						value={value}
						onChange={e => onChange(e.target.value)}
					/>
				</MDBox>
			);
	}
};
/**
 * Component for create a form from a dictionary
 */
export function LittleForm({
	object,
	metadatasSkeleton,
	handleChange,
	level = 0,
	path = null,
	listAPIs = null,
	errorUniqueFields
}) {
	if (object.items) {
		object = object.items;
	}

	const [regexEmail, setRegexEmail] = useState("");
	const [listOfApis, setListOfApis] = useState([]);

	let keys = Object.keys(object);
	const dispatch = useDispatch();
	const assistantIDs = useSelector(state => selectCurrentProfile(state).assistantID);

	useEffect(() => {
		const onSuccess = res => {
			setRegexEmail(res.regex);
		};
		const onSuccessApis = res => {
			setListOfApis(res?.apisList || []);
		};

		if (regexEmail === "") {
			dispatch(FormActions.getRegexLittleForm("email", onSuccess));
		}
		if (lod_.isEmpty(listOfApis)) {
			if (listAPIs) {
				setListOfApis(listAPIs);
			} else {
				dispatch(FormActions.getListApis(assistantIDs, onSuccessApis));
			}
		}
	}, []);

	return (
		<div
			style={{
				marginLeft: `${level * 1}rem`
			}}
		>
			{keys.map((key, index) => {
				let item = object[key];
				let display = item.display ?? true;
				if (!display) return null;
				if (item.type === "level") {
					return (
						<div
							key={index}
							style={{
								border: "1px solid #ccc",
								padding: "1rem",
								marginBottom: "1rem",
								borderRadius: "5px"
							}}
						>
							<h3>{item.label.fr}</h3>
							<LittleForm
								object={item.items}
								level={level + 1}
								metadatasSkeleton={metadatasSkeleton}
								handleChange={handleChange}
								path={path ? `${path}.${key}` : key}
								listOfApis={listOfApis}
								errorUniqueFields={errorUniqueFields}
							/>
						</div>
					);
				} else {
					return (
						<MDBox pt={1}>
							<FormInput
								key={index}
								item={item}
								datas={metadatasSkeleton}
								handleChange={handleChange}
								regexEmail={regexEmail}
								path={path ? `${path}.${key}` : key}
								listOfApis={listOfApis}
								errorUniqueFields={errorUniqueFields}
							/>
						</MDBox>
					);
				}
			})}
		</div>
	);
}
