/* eslint-disable react/no-danger */
/* eslint-disable consistent-return */
/**
 * Main application knowledge
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Icon, IconButton, Tooltip } from "@mui/material";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import ChartsActions from "redux-react/actions/chartsActions";
import FaiqActions from "redux-react/actions/faiqActions";

const Message = ({ message }) => {
	let styleDirection = {};

	let { direction, text } = message;

	if (direction === "in") {
		styleDirection = {
			left: 10,
			maxWidth: "80%",
			marginRight: "20%",
			backgroundColor: "white",
			borderRadius: "15px 15px 15px 0",
			padding: 10
		};
	} else {
		styleDirection = {
			right: 10,
			maxWidth: "80%",
			marginLeft: "20%",
			backgroundColor: "#2196f3",
			color: "white",
			fontWeight: "bold",
			borderRadius: "15px 15px 0 15px",
			padding: 10
		};
	}

	return (
		<MDBox mt={1} style={styleDirection}>
			<p
				style={{
					fontSize: 12,
					whiteSpace: "pre-wrap"
				}}
				dangerouslySetInnerHTML={{ __html: text }}
			></p>
		</MDBox>
	);
};

const ConversationBox = ({ conversation }) => {
	return (
		<MDBox
			style={{
				height: "95%"
			}}
			display="flex"
			flexDirection="column"
		>
			<MDBox
				p={1}
				flex="1"
				borderRadius="md"
				style={{
					position: "relative",
					backgroundColor: "rgb(236 236 236)",
					overflowY: "auto",
					height: "100%"
				}}
			>
				{conversation.messages.map((message, index) => (
					<MDBox key={index}>
						<Message message={{ text: message.question, direction: "out" }} />
						<Message message={{ text: message.reply, direction: "in" }} />
					</MDBox>
				))}
			</MDBox>
		</MDBox>
	);
};

/**
 * Page to display reviews from FAIQ plugin
 * @param {Object} route
 * @returns
 */
export default function ReviewPage({ route }) {
	const { profile, filters } = useSelector(state => state);
	const dispatch = useDispatch();
	// Charts states
	const [rawCharts, setRawCharts] = useState({});
	const [pageFilters, setPageFilters] = useState([]);
	const [paginedList, setPaginedList] = useState(null);
	// Load charts
	const [reloadTable, setReloadTable] = useState(false);

	const rightPannelContainer = useRef(null);
	const [openPannel, setOpenPannel] = useState(false);
	const [currentConversation, setCurrentConversation] = useState(null);

	const closePannel = () => {
		setOpenPannel(false);
		setCurrentConversation(null);
	};

	function loadPaginedList() {
		const onSuccess = res => {
			setPageFilters(res.filters);
			setRawCharts({ ...rawCharts, [route.route]: res.charts });
		};

		dispatch(ChartsActions.getCharts(profile.assistantID, route, onSuccess));
	}
	// Build charts
	function buildRawCharts() {
		// Success handler : set charts data
		const onSuccess = res => {
			setPaginedList(res.charts[0]);
			setReloadTable(!reloadTable);
		};
		// Build charts
		if (!rawCharts[route.route]) return;
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);

		let actualFilters = getLocalStorageBackValues(
			profile.selectedAssistant.assistantID,
			route.route,
			filters
		);

		dispatch(
			ChartsActions.buildRawCharts(
				rawCharts[route.route],
				actualFilters,
				mandatoryFilters,
				onSuccess
			)
		);
	}

	const openConversationPannel = ({ values }) => {
		dispatch(
			FaiqActions.getConversationByThreadId(profile.assistantID, values.threadID, res => {
				setCurrentConversation({ threadID: values.threadID, messages: res });
				setOpenPannel(true);
			})
		);
	};

	useEffect(() => {
		buildRawCharts();
	}, [rawCharts]);

	useEffect(() => {
		setPaginedList(null);
		loadPaginedList();
	}, [profile.selectedAssistant.assistantID, route]);

	return (
		<DashboardLayout>
			<MDBox py={3}>
				<DashboardNavbar
					filters={[
						<MDBox display="flex">
							{parseFilters(profile.assistantID, route.route, pageFilters, filters, dispatch)}
						</MDBox>
					]}
				/>
			</MDBox>

			{paginedList && (
				<MDBox
					className="pageContentContainer"
					style={{
						display: openPannel ? "flex" : "block"
					}}
				>
					<MDBox flex="1">
						<DefaultDataTable
							onRowClick={values => {
								openConversationPannel({ values });
							}}
							isSelectedRow={values => {
								if (!currentConversation) {
									return false;
								}

								return values.threadID === currentConversation?.threadID;
							}}
							form={route.form}
							dictionary={paginedList.dictionary}
							canSearch
							table={paginedList.data}
							display={paginedList.request.attributesDisplay}
							pagination={paginedList.pagination}
							list={paginedList}
							actions={[
								<IconButton handleclick={openConversationPannel}>
									<Tooltip title="Afficher la conversation" placement="top">
										<Icon fontSize="medium">message</Icon>
									</Tooltip>
								</IconButton>
							]}
							reloadTable={reloadTable}
							filters={{
								...getLocalStorageBackValues(
									profile.selectedAssistant.assistantID,
									route.route,
									filters
								)
							}}
						/>
					</MDBox>
					{openPannel && (
						<MDBox className="rightPannelContainer" ref={rightPannelContainer}>
							<MDBox
								className="rightPannel"
								sx={{ ml: 2 }}
								style={{
									maxWidth: "30vw"
								}}
							>
								<Card sx={{ p: 2, height: "100%" }}>
									<MDBox display="flex" alignItems="center" justifyContent="space-between">
										<MDBox>Conversation</MDBox>
										<MDBox>
											<Tooltip placement="top" title="Fermer">
												<IconButton onClick={() => closePannel()}>
													<Icon>close</Icon>
												</IconButton>
											</Tooltip>
										</MDBox>
									</MDBox>
									{/* Conversation */}
									{currentConversation && <ConversationBox conversation={currentConversation} />}
								</Card>
							</MDBox>
						</MDBox>
					)}
				</MDBox>
			)}

			<MDBox mt={7}></MDBox>
		</DashboardLayout>
	);
}
