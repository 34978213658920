/* eslint-disable no-unreachable */
/* eslint-disable no-empty-function */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import axios from "axios";
import store from "redux-react/store";

export async function getUploadSignedUrl(file, path, bucket) {
	const endPoint = process.env.REACT_APP_AMBACK;

	let user = store.getState().user;
	let assistantID = store.getState().profile.assistantID;

	let uploadSignedUrlResponse = await axios(endPoint + "/api/v1/s3/v3/getUploadSignedUrl", {
		headers: {
			Authorization: `Bearer ${user.token}`,
			UserEmail: user.email
		},
		method: "POST",
		data: {
			assistantID,
			fileName: file.name,
			path,
			bucket
		}
	});

	return uploadSignedUrlResponse.data.payload.result;
}

export async function getDownloadSignedUrl(file, path, bucket) {
	const endPoint = process.env.REACT_APP_AMBACK;

	let user = store.getState().user;
	let assistantID = store.getState().profile.assistantID;

	let uploadSignedUrlResponse = await axios(endPoint + "/api/v1/s3/v3/getDownloadSignedUrl", {
		headers: {
			Authorization: `Bearer ${user.token}`,
			UserEmail: user.email
		},
		method: "POST",
		data: {
			assistantID,
			fileName: file.name,
			path,
			bucket
		}
	});

	return uploadSignedUrlResponse.data.payload.result;
}

export async function getSignedUrlS3(file) {
	const endPoint = process.env.REACT_APP_AMBACK;

	let user = store.getState().user;
	let assistantID = store.getState().profile.assistantID;

	let uploadSignedUrlResponse = await axios(endPoint + "/api/v1/s3/v3/getSignedUrl", {
		headers: {
			Authorization: `Bearer ${user.token}`,
			UserEmail: user.email
		},
		method: "POST",
		data: {
			assistantID,
			size: file.size,
			fileName: file.name,
			contentType: file.type
		}
	});

	return uploadSignedUrlResponse.data.payload.result;
}

export async function uploadToSignedUrl(signedUrl, file) {
	const response = await axios.put(signedUrl, file, {
		headers: {
			"Content-Type": file.type
		}
	});

	return {};
}

export async function removeToSignedUrl(urlFile) {}

export async function deleteFileUrl(fileName, path) {
	const endPoint = process.env.REACT_APP_AMBACK;

	let user = store.getState().user;
	let assistantID = store.getState().profile.assistantID;

	await axios(endPoint + "/api/v1/s3/v3/deleteFileUrl", {
		headers: {
			Authorization: `Bearer ${user.token}`,
			UserEmail: user.email
		},
		method: "POST",
		data: {
			assistantID,
			fileName,
			path
		}
	});
}

export async function downloadAndUploadFileFromUrl(fileName, path, url) {
	const endPoint = process.env.REACT_APP_AMBACK;

	let user = store.getState().user;
	let assistantID = store.getState().profile.assistantID;

	let result = await axios(endPoint + "/api/v1/s3/v3/downloadAndUploadFileFromUrl", {
		headers: {
			Authorization: `Bearer ${user.token}`,
			UserEmail: user.email
		},
		method: "POST",
		data: {
			assistantID,
			fileName,
			path,
			url
		}
	});

	return result.data.payload;
}

export async function renameFile(path, oldName, newName) {
	const endPoint = process.env.REACT_APP_AMBACK;

	let user = store.getState().user;
	let assistantID = store.getState().profile.assistantID;

	let result = await axios(endPoint + "/api/v1/s3/v3/renameFile", {
		headers: {
			Authorization: `Bearer ${user.token}`,
			UserEmail: user.email
		},
		method: "POST",
		data: {
			assistantID,
			path,
			oldName,
			newName
		}
	});

	return result.data.payload;
}
