import { createAction } from "@reduxjs/toolkit";
import i18n from "i18n";
// import { errorMsg } from "redux-react/reducers/snackMsgsReducers";

const appBaseUrl = process.env.REACT_APP_AMBACK;

const api = ({
	type = "api/call",
	customUrl = null,
	url = "",
	method = "GET",
	onLoading = null,
	data = null,
	onSuccess,
	onFailure = () => {},
	accessToken = null,
	headers = null,
	withCredentials = false,
	trackLoading = false,
	/* Special stream */
	stream = false, // Is a stream request
	onBeforeStream, // One event before the stream start
	onDataStream, // Events while the stream is running
	onEndStream, // One event when the stream ends
	onAfterStream // One event after the stream ends
}) => {
	if (!onSuccess) {
		if (type === "api/call") {
			onSuccess = () => {};
		} else {
			onSuccess = createAction(`${type}Success`);
		}
	}
	if (trackLoading) {
		onLoading = createAction(`${type}Loading`);
	}
	return {
		type,
		payload: {
			isCustomUrl: !!customUrl,
			requestUrl: customUrl || appBaseUrl + url,
			method,
			data,
			accessToken,
			onLoading,
			onSuccess,
			onFailure,
			headers,
			withCredentials,
			/* Special stream */
			stream,
			onBeforeStream,
			onDataStream,
			onEndStream,
			onAfterStream
		}
	};
};

const apiError = createAction("api/error", (error, label) => ({
	error: error && error.message ? error.message : error,
	payload: label
}));
const apiAccessDenied = createAction("api/access_denied");

export { api, apiError, apiAccessDenied };
