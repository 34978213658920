/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	InputAdornment
} from "@mui/material";
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import lod_ from "lodash";
import MDTypography from "components/Basics/MDTypography";
import { LittleForm } from "components/Custom/LittleForm";
import { renameFile } from "helpers/s3";
import { createDictionarySkeleton } from "helpers/form";

const NameInput = ({ source, changeName }) => {
	let name = source["file.name"];

	let extension = `.${name.split(".").pop()}`;
	let nameWithoutExtension = name.split(".").slice(0, -1).join(".");

	const [newName, setNewName] = useState(nameWithoutExtension);

	useEffect(() => {
		let completeName = `${newName}${extension}`;
		changeName(completeName);
	}, [newName]);

	return (
		<MDInput
			// disabled
			className="dialogInput"
			label="Nom du fichier"
			value={newName}
			onChange={e => setNewName(e.target.value)}
			InputProps={{
				endAdornment: <InputAdornment position="end">{extension}</InputAdornment>
			}}
		/>
	);
};

export default function EditSourceDialog({
	open,
	handleCloseDialog,
	handleSave,
	metadatas,
	source,
	metadatasDictionary
}) {
	// File name
	const [fileName, setFileName] = useState("");
	// Metadatas skeleton
	const [metadatasSkeleton, setMetadatasSkeleton] = useState(
		createDictionarySkeleton(metadatasDictionary, metadatas)
	);
	// Disable button
	const isDisabled = false;
	// Close dialog
	function close() {
		handleCloseDialog();
	}
	// Save source
	async function submit() {
		let name = source["file.name"];
		let url = source.url;
		if (fileName !== source["file.name"]) {
			name = fileName;
			let result = await renameFile(source["file.path"], source["file.name"], fileName);
			url = result.url;
		}
		handleSave({
			code: source.code,
			update: { metadatas: metadatasSkeleton, "file.name": name, url: url }
		});
		close();
	}
	// Change metadatas
	const handleChange = (path, value) => {
		let clone = lod_.cloneDeep(metadatasSkeleton);
		lod_.set(clone, path, value);
		setMetadatasSkeleton(clone);
	};

	const changeName = newName => {
		setFileName(newName);
	};

	return (
		<Dialog fullWidth maxWidth="xl" open={open} onClose={() => close()}>
			<DialogTitle>{i18n.t("SOURCES.EDIT.editSource")}</DialogTitle>
			<DialogContent>
				<MDBox
					sx={{ ml: 10, mr: 10 }}
					display="flex"
					flexDirection="column"
					justifyContent="center"
				>
					<MDBox flex="1">
						<MDTypography variant="h5">{i18n.t("SOURCES.sourceFile")}</MDTypography>
						<MDBox mt={2} display="flex" alignItems="center" flexDirection="row">
							<MDBox display="flex" alignItems="center" flex="1">
								<Icon fontSize="large">description</Icon>
								<MDBox ml={1} mr={1} flex="1">
									<NameInput source={source} changeName={changeName} />
								</MDBox>
							</MDBox>

							<MDBox>
								<a href={source.url} target="_blank">
									<MDButton color="info">{i18n.t("SETTINGS.visualize")}</MDButton>
								</a>
							</MDBox>
						</MDBox>
					</MDBox>
					<MDBox flex="2" mt={4}>
						<MDTypography variant="h5">{i18n.t("SOURCES.metadatas")}</MDTypography>
						<MDBox mt={2}>
							<LittleForm
								object={metadatasDictionary}
								metadatasSkeleton={metadatasSkeleton}
								handleChange={handleChange}
							/>
						</MDBox>
					</MDBox>
				</MDBox>
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="info" onClick={() => close()}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton disabled={isDisabled} variant="contained" color="info" onClick={submit}>
					{i18n.t("SETTINGS.edit")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}
