/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
/**
 * #######################################################@
 *
 * Charts settings
 *
 * #######################################################@
 */
import "./style.css";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";

import { Divider, Icon, IconButton, Tooltip } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import ChartsActions from "redux-react/actions/chartsActions";
import SettingsActions from "redux-react/actions/settingsActions";
import { display, hide } from "redux-react/reducers/snackBarReducer";
import { useNavigate } from "react-router-dom";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import ConfirmDialog from "components/Custom/Dialogs/ConfirmDialog";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import AddChartDialog from "./AddChartDialog";

export default function SettingsCharts({ route }) {
	const { profile } = useSelector(state => state);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// Page Charts
	const [paginedList, setPaginedList] = useState(null);
	// Add new chart dialog state
	const [addNewChart, setAddNewChart] = useState(false);
	// Confirm dialog state
	const [confirmDialog, setConfirmDialog] = useState({
		open: false,
		title: "",
		content: "",
		handleConfirm: () => {}
	});
	const [reloadTable, setReloadTable] = useState(false);
	// Load charts from back
	// Load pagined lists
	function loadPaginedList() {
		const onSuccess = res => {
			setPaginedList(res.paginedList[0]);
		};

		dispatch(ChartsActions.getPaginedList(profile.assistantID, route, onSuccess));
	}
	// Handle save new chart
	const handleSaveNewChart = ({ chart, edit }) => {
		if (edit) {
			const onSuccess = res => {
				dispatch(
					display({
						message: i18n.t("SETTINGS.CHARTS.SUCCESS.edit"),
						type: "success"
					})
				);
				setReloadTable(!reloadTable);
			};
			dispatch(SettingsActions.editSetting(profile.assistantID, "chart", chart, onSuccess));
		} else {
			const onSuccess = res => {
				let code = chart.code;
				let page = chart.display.pages[0];
				dispatch(
					display({
						type: "info",
						message: i18n.t("SETTINGS.CHARTS.SUCCESS.add"),
						validation: (
							<>
								<MDButton
									variant="contained"
									color="info"
									sx={{ ml: 1 }}
									onClick={() => {
										dispatch(hide());
										let anchorRef = `#${code}`;
										navigate(`/${page}${anchorRef}`);
									}}
								>
									{i18n.t("SETTINGS.see")}
								</MDButton>
								<MDButton
									sx={{ ml: 1 }}
									onClick={() => {
										dispatch(hide());
									}}
								>
									{i18n.t("SETTINGS.close")}
								</MDButton>
							</>
						)
					})
				);
				setReloadTable(!reloadTable);
			};
			dispatch(SettingsActions.newSetting(profile.assistantID, "chart", chart, onSuccess));
		}
	};
	// Handle delete chart
	const actionDeleteHandle = ({ values }) => {
		const handleConfirmDialog = () => {
			setConfirmDialog({
				open: false
			});
			const onSuccess = res => {
				dispatch(
					display({
						message: i18n.t("SETTINGS.CHARTS.SUCCESS.delete"),
						type: "success"
					})
				);
				setReloadTable(!reloadTable);
			};
			dispatch(
				SettingsActions.deleteSetting(
					profile.assistantID,
					"chart",
					{ code: values.code },
					onSuccess
				)
			);
		};

		setConfirmDialog({
			open: true,
			title: `${i18n.t("SETTINGS.delete")} : ${i18n.t(`SETTINGS.CHARTS.confirmDelete`)} ${
				values["display.title"] ? `"${values["display.title"]}"` : ""
			}`,
			content: `${i18n.t("SETTINGS.deleteConfirmation")} : ${i18n.t(
				`SETTINGS.CHARTS.confirmDelete`
			)} ${values["display.title"] ? `"${values["display.title"]}"` : ""} ?`,
			handleConfirm: handleConfirmDialog
		});
	};
	/* Get chart by code */
	const getChartByCode = code => {
		const onSuccess = res => {
			if (res.chart) {
				setAddNewChart({ open: true, edit: true, chart: res.chart, step: 3 });
			} else {
				dispatch(
					display({
						message: i18n.t("SETTINGS.CHARTS.ERROR.notFound"),
						type: "error"
					})
				);
			}
		};
		dispatch(ChartsActions.getChartByCode(profile.assistantID, code, onSuccess));
	};

	useEffect(() => {
		loadPaginedList();
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar
				filters={[
					<MDBox display="flex">
						<MDButton
							style={{ height: "100%", marginRight: "0.75rem" }}
							variant="contained"
							color="info"
							onClick={() => setAddNewChart({ open: true, edit: false, chart: {}, step: 0 })}
						>
							<Icon>add</Icon>&nbsp;{i18n.t("SETTINGS.CHARTS.add")}
						</MDButton>
					</MDBox>
				]}
			/>
			<Divider />
			{paginedList && (
				<DefaultDataTable
					id={paginedList.code}
					reloadTable={reloadTable}
					dictionary={paginedList.dictionary}
					form={route.form}
					list={paginedList}
					pagination={paginedList.pagination}
					canSearch
					table={paginedList.data}
					display={paginedList.request.attributesDisplay}
					actions={[
						<IconButton
							customdisabled={({ values }) => {
								return values.type === "multiCollection";
							}}
							handleclick={({ values }) => {
								getChartByCode(values.code);
							}}
						>
							<Tooltip title={i18n.t("SETTINGS.edit")} placement="top">
								<Icon fontSize="medium">edit</Icon>
							</Tooltip>
						</IconButton>,
						<IconButton handleclick={actionDeleteHandle}>
							<Tooltip title={i18n.t("SETTINGS.delete")} placement="top">
								<Icon fontSize="medium">delete</Icon>
							</Tooltip>
						</IconButton>
					]}
				/>
			)}
			{paginedList && (
				<AddChartDialog
					collections={paginedList.collections}
					open={addNewChart.open}
					handleCloseDialog={() => setAddNewChart({ open: false })}
					handleSave={handleSaveNewChart}
					edit={addNewChart.edit}
					chart={addNewChart.chart}
					step={addNewChart.step}
				/>
			)}
			<ConfirmDialog
				open={confirmDialog.open}
				title={confirmDialog.title}
				content={confirmDialog.content}
				handleClose={() => {
					setConfirmDialog({
						open: false
					});
				}}
				handleConfirm={confirmDialog.handleConfirm}
			/>
		</DashboardLayout>
	);
}
