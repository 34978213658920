/* eslint-disable no-plusplus */
import { Icon } from "@mui/material";
import lod_ from "lodash";
import { useAuth0 } from "react-auth0-wrapper";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import ChartsPage from "pages/charts";
import MDAvatar from "components/Basics/MDAvatar";
import SettingsPages from "pages/settings/pages";
import SettingsFilters from "pages/settings/filters";
import SettingsLabels from "pages/settings/labels";
import KnowledgePage from "pages/knowledge";
import HistoryPage from "pages/history";
import SettingsAccountPage from "pages/settingsPage";
import StatisticPage from "pages/statPage";
import FormsPage from "pages/forms";
import SourcesPage from "pages/sources";
import SettingsOthers from "pages/settings/others";
import SettingsCharts from "pages/settings/charts";
import TestingPage from "pages/testing";
import ReviewPage from "pages/review";

export default function Logout() {
	const { logout } = useAuth0();
	logout({ returnTo: window.location.origin });
	return (
		<DashboardLayout>
			<MDBox py={3}>
				<MDTypography>Chargement ...</MDTypography>
			</MDBox>
		</DashboardLayout>
	);
}

/**
 * When page is defined in the configuration but not in the code
 */
const NotFound = () => {
	return (
		<DashboardLayout>
			<MDBox py={3}>
				<MDTypography>Page is not defined :/</MDTypography>
			</MDBox>
		</DashboardLayout>
	);
};

/**
 * Get correct component & icon for custom routes
 * Based on "key" field
 */
function getMyComponent(route) {
	let { type, name, icon, component } = route;
	const EXCEPTION = ["/login", "/logout", "/profile"];

	switch (type) {
		case "title":
		case "divider":
			return {
				component: null,
				icon: null
			};
		default:
			break;
	}

	let pageIcon = (
		<MDAvatar bgColor="dark" alt={name} size="sm">
			{name[0]?.toUpperCase()}
		</MDAvatar>
	);

	if (icon) {
		if (typeof icon === "string") {
			pageIcon = <Icon fontSize="medium">{icon}</Icon>;
		} else {
			pageIcon = icon;
		}
	}

	switch (route.route) {
		case "/pages-editor":
			return {
				component: <SettingsPages route={route} />,
				icon: pageIcon
			};
		case "/filters-editor":
			return {
				component: <SettingsFilters route={route} />,
				icon: pageIcon
			};
		case "/labels-editor":
			return {
				component: <SettingsLabels route={route} />,
				icon: pageIcon
			};
		case "/charts-editor":
			return {
				component: <SettingsCharts route={route} />,
				icon: pageIcon
			};
		case "/knowledge":
			return {
				component: <KnowledgePage route={route} />,
				icon: pageIcon
			};
		case "/history":
			return {
				component: <HistoryPage route={route} />,
				icon: pageIcon
			};
		case "/settings":
			return {
				component: <SettingsAccountPage route={route} />,
				icon: pageIcon
			};
		case "/statistiques":
			return {
				component: <StatisticPage route={route} />,
				icon: pageIcon
			};
		case "/sources":
			return {
				component: <SourcesPage route={route} />,
				icon: pageIcon
			};
		case "/testing":
			return {
				component: <TestingPage route={route} />,
				icon: pageIcon
			};
		case "/review":
			return {
				component: <ReviewPage route={route} />,
				icon: pageIcon
			};
		case "/others-editor":
			return {
				component: <SettingsOthers route={route} />,
				icon: pageIcon
			};
		default:
			if (EXCEPTION.includes(route.route)) {
				return {
					component: null,
					icon: null
				};
			}
			if (route?.form?.active) {
				return {
					component: <FormsPage route={route} />,
					icon: pageIcon
				};
			}
			return {
				component: component ?? <ChartsPage route={route} />,
				icon: pageIcon
			};
	}
}

/**
 * Build custom routes :
 * - add component
 * - add icon
 */
export function buildCustomRoutes(routes) {
	let copy = lod_.cloneDeep(routes);

	let buildedRoutes = [];

	for (let i = 0; i < copy.length; i++) {
		let index = i;
		let route = copy[index];

		let { component, icon } = getMyComponent(route);

		/**
		 * Case when route component is not defined in the code
		 */
		if (!route.collapse && route.route && !route.component && !component) {
			component = <NotFound />;
			icon = <Icon fontSize="medium">error</Icon>;
		}

		if (component) {
			route.component = component;
		}

		if (icon) {
			route.icon = icon;
		}

		if (route.collapse) {
			route.collapse = buildCustomRoutes(route.collapse);
		}

		buildedRoutes.push(route);
	}

	return buildedRoutes;
}
