/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable object-shorthand */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import { useEffect } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { CircularProgress, Icon } from "@mui/material";
import MDInput from "components/Basics/MDInput";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import FaiqActions from "redux-react/actions/faiqActions";

export default function Step2Summary({
	validStep,
	source,
	skeleton,
	onChange,
	lockGenerateButton
}) {
	const dispatch = useDispatch();
	const profile = useSelector(selectCurrentProfile);

	const generateSummary = () => {
		lockGenerateButton();
		dispatch(FaiqActions.generateSummary(profile.assistantID, source.code, res => {}));
	};

	useEffect(() => {
		validStep();
		// handleSave(metadatasSkeleton);
	}, []);

	return (
		<MDBox sx={{ ml: 10, mr: 10 }} display="flex" flexDirection="column" justifyContent="center">
			<MDBox flex="1">
				<MDBox display="flex" alignItems="center" flexDirection="row">
					<MDBox display="flex" alignItems="center" flex="1">
						<Icon fontSize="medium">description</Icon>
						<MDBox ml={1} mr={1} flex="1">
							<MDTypography variant="h5">Résumé du document</MDTypography>
						</MDBox>
					</MDBox>

					<MDBox alignItems="center">
						<MDButton
							color="info"
							onClick={generateSummary}
							disabled={skeleton.summaryGenerationDisabled}
						>
							Générer un résumé&nbsp;
							{skeleton.summaryGenerationDisabled ? (
								<CircularProgress size={16} color="white" />
							) : (
								<Icon>auto_awesome</Icon>
							)}
						</MDButton>
					</MDBox>
				</MDBox>
			</MDBox>
			<MDBox
				mt={3}
				style={{
					overflow: "auto",
					maxHeight: "60vh"
				}}
			>
				{/* TextArea */}
				<MDInput
					multiline
					rows={26}
					value={skeleton.summary}
					onChange={e => onChange(e.target.value)}
					placeholder="Résumé du document"
					fullWidth
				/>
			</MDBox>
		</MDBox>
	);
}
