/* eslint-disable no-continue */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable object-shorthand */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-syntax */
import { useEffect, useState } from "react";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import { Divider, Icon, IconButton, Menu, MenuItem, Switch, Tooltip } from "@mui/material";
import MDTypography from "components/Basics/MDTypography";
import LogicManager from "components/Custom/LogicManager";
import { LittleForm } from "components/Custom/LittleForm";
import MDBadge from "components/Basics/MDBadge";
import i18n from "i18n";
import { createDictionarySkeleton } from "helpers/form";
/**
 * Condition box element, display logic / name / output from a rule config
 */
export default function ConditionBox({
	cond,
	dictionary,
	updateLogic,
	updateName,
	updateActive,
	outputDictionary,
	handleSaveOutput,
	removeCondition
}) {
	const [expended, setExpended] = useState(true);
	const [anchorElMenu, setAnchorElMenu] = useState(null);
	// Line array is used to display the rule's logic, 1 line = 1 condition
	const [lineArray, setLineArray] = useState([]);
	// Edit name bool
	const [editNameBool, setEditNameBool] = useState(false);
	// Temporary name
	const [temporaryName, setTemporaryName] = useState(cond.name);
	// Output skeleton used to edit the output
	const [outputSkeleton, setOutputSkeleton] = useState({});
	/* Add new "condition" to the logic */
	const handleAddLine = filter => {
		let realPath = filter.replaceAll(".", ".items.");
		let dicObject = lod_.get(dictionary, realPath);
		let name = filter.split(".")[filter.split(".").length - 1];
		// user can add only one filter by attribute
		let existInArray = lineArray.find(f => f.name === name);

		if (dicObject && !existInArray) {
			let copy = [
				...lineArray,
				{
					filter: dicObject,
					name
				}
			];

			setLineArray(copy);
		}
	};
	/* Save logic */
	const saveLogic = list => {
		let partialParts = [];
		// Get only valid lines of the array (which have method, value and path)
		let validList = list.filter(f => f.method && f.value && f.path);
		// Create partial condition logic for each line
		for (let line of validList) {
			let partialCondition;
			switch (line.method) {
				// For IN operator, value must be the first item to check in string in 2nd pos
				case "in":
					partialCondition = `{ "${line.method}": [  "${line.value}" , {{{ json ${line.path} }}} ] }`;
					break;
				default:
					partialCondition = `{ "${line.method}": [ {{{ json ${line.path} }}} , "${line.value}" ] }`;
					break;
			}
			partialParts.push(partialCondition);
		}
		// Build the json logic
		let buildedJsonLogic = `{ "and": [ ${partialParts.join(",")} ] }`;
		updateLogic(buildedJsonLogic);
	};
	/* Remove a "condition" from the logic */
	const handleRemoveLine = name => {
		let copy = lineArray.filter(f => f.name !== name);
		setLineArray(copy);
		saveLogic(copy);
	};
	/* handle logic chanche */
	const handleOnChange = obj => {
		// Update the line's array
		let updatedList = lineArray.map(f => {
			if (f.name === obj.name) {
				// Only method / value can change
				f.method = obj.method;
				f.value = obj.value;
				f.path = obj.path;
			}
			return f;
		});
		setLineArray(updatedList);
		saveLogic(updatedList);
	};
	/* Save condition name */
	const saveNewName = () => {
		if (!temporaryName.trim()) return;
		setEditNameBool(false);
		updateName(temporaryName);
	};
	/* Abort condition's name edition */
	const stopNewName = () => {
		setEditNameBool(false);
		setTemporaryName(cond.name);
	};
	/* Handle change output values */
	const handleChange = (path, value) => {
		let clone = lod_.cloneDeep(outputSkeleton);
		lod_.set(clone, path, value);
		handleSaveOutput(clone);
		setOutputSkeleton(clone);
	};
	/* Fill logic fields on component load */
	useEffect(() => {
		let skeleton = createDictionarySkeleton(outputDictionary, cond.output);
		setOutputSkeleton(skeleton);

		setLineArray([]);
		// Parse logic to JSON
		let logic = cond.logic;
		logic = logic.replaceAll("{{{ json", '"');
		logic = logic.replaceAll("}}}", '"');
		let logicJSON = JSON.parse(logic);
		// Get only the 'and' part of the logic (actually must be the only one)
		logicJSON = logicJSON.and;
		// For each operator, get the filter, method and value
		for (let singleOperator of logicJSON) {
			let operator = Object.keys(singleOperator)[0];
			let item;
			let value;
			switch (operator) {
				// For IN operator, value must be the first item to check in string in 2nd pos
				case "in":
					item = singleOperator[operator][1];
					value = singleOperator[operator][0];
					break;
				default:
					item = singleOperator[operator][0];
					value = singleOperator[operator][1];
					break;
			}

			let dicPath = item.replaceAll(".", ".items.");
			let dictionaryItem = lod_.get(dictionary, dicPath.trim());

			if (!dictionaryItem) {
				continue;
			}

			let newLine = {
				filter: dictionaryItem,
				method: operator,
				name: dictionaryItem?.attribute,
				path: item.trim(),
				value: value
			};

			setLineArray(prevState => [...prevState, newLine]);
		}
	}, []);

	return (
		<MDBox bgColor="light" p={2} mb={1} borderRadius="lg">
			{/* Condition's name */}
			{editNameBool && (
				<MDBox mb={1} display="flex" alignItems="center">
					<MDInput
						size="large"
						type="text"
						fullWidth
						value={temporaryName}
						onChange={e => {
							setTemporaryName(e.target.value);
						}}
						onKeyDown={e => {
							if (e.key === "Enter") {
								saveNewName();
							}
							if (e.key === "Escape") {
								stopNewName();
							}
						}}
						inputProps={{
							style: {
								fontSize: "1.5rem",
								color: "#344767",
								fontWeight: "700",
								letterSpacing: "0.00735em",
								opacity: "1",
								textTransform: "none",
								verticalAlign: "unset"
							}
						}}
					/>
					<IconButton color="success" onClick={saveNewName}>
						<Icon>check</Icon>
					</IconButton>
					<IconButton color="error" onClick={stopNewName}>
						<Icon>close</Icon>
					</IconButton>
				</MDBox>
			)}
			{!editNameBool && (
				<MDBox display="flex" alignItems="center" justifyContent="space-between">
					<MDTypography variant="h4" onDoubleClick={e => setEditNameBool(true)}>
						{cond.name}
					</MDTypography>
					<MDBox display="flex" alignItems="center">
						<MDBadge
							color={Boolean(cond.active) ? "success" : "error"}
							badgeContent={Boolean(cond.active) ? "Actif" : "Inactif"}
						/>
						<IconButton
							onClick={e => {
								setAnchorElMenu(e.currentTarget);
							}}
						>
							<Icon>more_vert</Icon>
						</IconButton>
						<IconButton
							onClick={() => {
								setExpended(!expended);
							}}
						>
							<Icon>{expended ? "expand_less" : "expand_more"}</Icon>
						</IconButton>
						{/* Menu */}
						<Menu
							anchorEl={anchorElMenu}
							open={Boolean(anchorElMenu)}
							onClose={() => setAnchorElMenu(null)}
						>
							{/* Actions */}
							<MenuItem disabled style={{ opacity: 1 }}>
								<MDTypography variant="caption">Actions</MDTypography>
							</MenuItem>
							<MenuItem onClick={() => removeCondition(cond.name)}>
								<Icon>delete</Icon>&nbsp;Supprimer
							</MenuItem>
							{/* Options */}
							<Divider />
							<MenuItem disabled style={{ opacity: 1 }}>
								<MDTypography variant="caption">Options</MDTypography>
							</MenuItem>
							<MenuItem
								style={{ justifyContent: "space-between" }}
								onClick={() => updateActive(!Boolean(cond.active))}
							>
								Actif
								<Switch checked={Boolean(cond.active)} />
							</MenuItem>
						</Menu>
					</MDBox>
				</MDBox>
			)}
			{expended && (
				<>
					<Divider />
					{/* Logic manager */}
					<MDBox mt={1} mb={1}>
						<LogicManager
							lineArray={lineArray}
							handleRemoveLine={handleRemoveLine}
							onChangeValue={handleOnChange}
							dictionary={dictionary}
							handleAddFilter={handleAddLine}
						/>
					</MDBox>
					{/* Output */}
					<MDBox mt={2}>
						<MDTypography variant="h5">{i18n.t("SETTINGS.DICTIONARY.output")}</MDTypography>
					</MDBox>
					<MDBox bgColor="white" borderRadius="lg" p={2}>
						{!lod_.isEmpty(outputSkeleton) && (
							<LittleForm
								object={outputDictionary}
								metadatasSkeleton={outputSkeleton}
								handleChange={handleChange}
							/>
						)}
					</MDBox>
				</>
			)}
		</MDBox>
	);
}
