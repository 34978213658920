/* eslint-disable no-case-declarations */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable object-shorthand */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import "./style.css";
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import i18n from "i18n";
import MDTypography from "components/Basics/MDTypography";
import {
	Card,
	Checkbox,
	CircularProgress,
	Icon,
	IconButton,
	Menu,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography
} from "@mui/material";
import lod_, { set } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentProfile } from "redux-react/reducers/profileReducer";
import FaiqActions from "redux-react/actions/faiqActions";
import MDBadge from "components/Basics/MDBadge";
import { display } from "redux-react/reducers/snackBarReducer";
import MDInput from "components/Basics/MDInput";
import { socket } from "redux-react/middleware/ws";

const KnowledgeRowEditMode = ({
	knowledge,
	setEditMode,
	getKnowledges,
	setFusionedKnowledgeUUID
}) => {
	const dispatch = useDispatch();
	const profile = useSelector(selectCurrentProfile);
	const [content, setContent] = useState(knowledge.content);
	const [textSelection, setTextSelection] = useState(null);

	const saveKnowledge = (forceContent = null) => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("La connaissance a été mise à jour avec succès"),
					type: "success"
				})
			);
			getKnowledges();
		};

		dispatch(
			FaiqActions.updateKnowledge(
				profile.assistantID,
				{
					uuid: knowledge.uuid,
					content: forceContent || content
				},
				onSuccess
			)
		);

		setEditMode(false);
	};

	const createKnowledge = e => {
		const onSuccess = res => {
			setFusionedKnowledgeUUID({
				type: "add",
				uuid: res.knowledge?.uuid
			});
			getKnowledges();
		};

		// 1- Create a new knowledge
		dispatch(
			FaiqActions.saveKnowledge(
				profile.assistantID,
				{
					source: knowledge.source,
					metadatas: knowledge.metadatas,
					language: knowledge.language,
					content: textSelection,
					rank: knowledge.rank + 1 || 0
				},
				onSuccess
			)
		);

		// 2- Update current knowledge
		saveKnowledge(content.replace(textSelection, ""));

		e.stopPropagation();
		e.preventDefault();
		setEditMode(false);
	};

	// when user select text, add it to textSelection
	const selectText = () => {
		const selection = window.getSelection();
		if (selection.toString().length > 0) {
			setTextSelection(selection.toString());
		} else {
			setTextSelection(null);
		}
	};

	useEffect(() => {
		window.addEventListener("mouseup", selectText);

		return () => {
			window.removeEventListener("mouseup", selectText);
		};
	}, []);

	return (
		<MDBox>
			<MDInput
				fullWidth
				multiline
				rows={15}
				value={content}
				onChange={e => {
					setContent(e.target.value);
				}}
			></MDInput>
			<MDBox
				mt={1}
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="space-between"
			>
				<MDButton color="info" onClick={createKnowledge} size="small" disabled={!textSelection}>
					<Icon>add</Icon>&nbsp;Créer une nouvelle connaissance avec la selection
				</MDButton>
				<MDBox display="flex" flexDirection="row" alignItems="center">
					<MDButton color="info" variant="outlined" onClick={() => setEditMode(false)} size="small">
						<Icon>close</Icon>&nbsp;Annuler
					</MDButton>
					<MDButton
						sx={{ ml: 1 }}
						color="info"
						onClick={() => {
							saveKnowledge();
						}}
						size="small"
					>
						<Icon>save</Icon>&nbsp;Enregistrer
					</MDButton>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

const ExtractedRowLine = ({
	knowledge,
	selectedRows = [],
	handleSelectRow,
	fusionedKnowledgeUUID,
	setFusionedKnowledgeUUID,
	source,
	setKnowledges,
	getKnowledges,
	setSelectedRows,
	setEditMode: setEditModeProps
}) => {
	const dispatch = useDispatch();
	const profile = useSelector(selectCurrentProfile);
	const isFusioned = fusionedKnowledgeUUID.uuid === knowledge.uuid;
	const isSelected = selectedRows.includes(knowledge.uuid);

	const [editMode, setEditMode] = useState(false);

	const [anchorEl, setAnchorEl] = useState(null);

	const editKnowledge = e => {
		e.stopPropagation();
		e.preventDefault();
		setSelectedRows(prev => prev.filter(row => row !== knowledge.uuid));
		setAnchorEl(null);
		setEditMode(true);
	};

	const deleteKnowledge = e => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("La connaissance a été supprimée avec succès"),
					type: "success"
				})
			);
			setKnowledges(res.knowledges);
		};

		dispatch(
			FaiqActions.deleteKnowledges(profile.assistantID, [knowledge.uuid], source.code, onSuccess)
		);

		e.stopPropagation();
		e.preventDefault();
		setAnchorEl(null);
	};

	useEffect(() => {
		setEditModeProps(editMode);
	}, [editMode]);

	return (
		<TableRow
			className={isSelected ? "ExtractedSelectedRow" : ""}
			onClick={() => !editMode && handleSelectRow(knowledge.uuid)}
			style={{
				cursor: "pointer"
			}}
		>
			<TableCell>{!editMode && <Checkbox checked={isSelected} />}</TableCell>
			<TableCell>
				{!editMode ? (
					<MDBadge
						invisible={!isFusioned}
						variant="contained"
						badgeContent={
							<MDBox display="flex" flexDirection="row" alignItems="center">
								<MDBox display="flex" flexDirection="row" alignItems="center">
									{fusionedKnowledgeUUID.type === "fusion" ? "Dernière fusion" : "Dernier ajout"}
								</MDBox>
								<MDBox display="flex" flexDirection="row" alignItems="center">
									<Icon>auto_awesome</Icon>
								</MDBox>
							</MDBox>
						}
						color={fusionedKnowledgeUUID.type === "fusion" ? "info" : "success"}
						circular
						size="xs"
						anchorOrigin={{
							vertical: "top",
							horizontal: isFusioned ? "center" : "left"
						}}
					>
						<Typography
							sx={{ mt: isFusioned ? 3 : 0 }}
							variant="body1"
							fontSize="small"
							className={isSelected ? "ExtractedSelectedRowText" : ""}
							dangerouslySetInnerHTML={{ __html: knowledge.content }}
						></Typography>
					</MDBadge>
				) : (
					<KnowledgeRowEditMode
						knowledge={knowledge}
						setEditMode={setEditMode}
						getKnowledges={getKnowledges}
						setFusionedKnowledgeUUID={setFusionedKnowledgeUUID}
					/>
				)}
			</TableCell>
			<TableCell>
				{!editMode && (
					<IconButton
						onClick={e => {
							e.stopPropagation();
							e.preventDefault();
							setAnchorEl(e.currentTarget);
						}}
					>
						<Icon>more_vert</Icon>
					</IconButton>
				)}
			</TableCell>

			<Menu
				id="simple-actions-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={e => {
					e.stopPropagation();
					e.preventDefault();
					setAnchorEl(null);
				}}
			>
				<MenuItem onClick={editKnowledge}>
					<Icon>edit</Icon>&nbsp;Modifier
				</MenuItem>
				<MenuItem onClick={deleteKnowledge}>
					<Icon>delete</Icon>&nbsp;Supprimer
				</MenuItem>
			</Menu>
		</TableRow>
	);
};

export default function Step3Extract({
	validStep,
	source,
	skeleton,
	extractKnowledges,
	setEditMode
}) {
	const dispatch = useDispatch();
	const profile = useSelector(selectCurrentProfile);

	const [knowledges, setKnowledges] = useState([]);
	const [loading, setLoading] = useState(true);

	const [fusionedKnowledgeUUID, setFusionedKnowledgeUUID] = useState({});

	const [selectedRows, setSelectedRows] = useState([]);
	// Actions menu
	const [anchorEl, setAnchorEl] = useState(null);

	const handleSelectRow = uuid => {
		if (selectedRows.includes(uuid)) {
			setSelectedRows(selectedRows.filter(row => row !== uuid));
		} else {
			setSelectedRows([...selectedRows, uuid]);
		}
	};

	const closeActionsMenu = () => {
		setAnchorEl(null);
	};

	const mergeKnowledges = () => {
		const onSuccess = res => {
			if (res.success) {
				dispatch(
					display({
						message: i18n.t("Les connaissances ont été fusionnées avec succès"),
						type: "success"
					})
				);
				setKnowledges(res.knowledges);
				setFusionedKnowledgeUUID({
					type: "fusion",
					uuid: res.newKnowledgeUUID
				});
			} else {
				dispatch(
					display({
						message: i18n.t("Les connaissances n'ont pas pu être fusionnées"),
						type: "success"
					})
				);
			}
		};

		dispatch(
			FaiqActions.mergeKnowledges(profile.assistantID, selectedRows, source.code, onSuccess)
		);

		dispatch(
			display({
				message: i18n.t("Fusion en cours..."),
				type: "info"
			})
		);

		closeActionsMenu();
		setSelectedRows([]);
	};

	const deleteKnowledges = () => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("Les connaissances ont été supprimées avec succès"),
					type: "success"
				})
			);
			setKnowledges(res.knowledges);
		};

		dispatch(
			FaiqActions.deleteKnowledges(profile.assistantID, selectedRows, source.code, onSuccess)
		);
		closeActionsMenu();
		setSelectedRows([]);
	};

	const getKnowledges = () => {
		dispatch(
			FaiqActions.getKnowledgesBySourceCode(profile.assistantID, source.code, res => {
				setKnowledges(res.knowledges);
				setLoading(false);
			})
		);
	};

	const sourceExtractedResult = data => {
		if (data.knowledges) {
			setKnowledges(data.knowledges);
		}
	};

	useEffect(() => {
		setLoading(true);
		getKnowledges();
		if (source.extracted) {
			validStep();
		}

		socket.on("source_extracted_result", sourceExtractedResult);

		return () => {
			socket.off("source_extracted_result", sourceExtractedResult);
		};
	}, []);

	return (
		<MDBox sx={{ ml: 10, mr: 10 }} display="flex" flexDirection="column" justifyContent="center">
			<MDBox flex="1">
				<MDBox display="flex" alignItems="center" flexDirection="row">
					<MDBox display="flex" alignItems="center" flex="1">
						<Icon fontSize="medium">description</Icon>
						<MDBox ml={1} mr={1} flex="1">
							<MDTypography variant="h5">Extraire les connaissances</MDTypography>
						</MDBox>
					</MDBox>

					<MDBox alignItems="center">
						{source.extracted && (
							<MDBox>
								<MDButton
									color="info"
									onClick={extractKnowledges}
									disabled={skeleton.extractionDisabled}
								>
									Réextraire les connaissances&nbsp;
									{skeleton.extractionDisabled ? (
										<CircularProgress size={16} color="white" />
									) : (
										<Icon>auto_awesome</Icon>
									)}
								</MDButton>
								<MDBadge color="error" badgeContent={selectedRows.length} circular size="xs">
									<MDButton
										color="info"
										onClick={e => {
											setAnchorEl(e.currentTarget);
										}}
										sx={{ ml: 1 }}
										disabled={selectedRows.length === 0}
									>
										<Icon>list_alt</Icon>Actions&nbsp;
									</MDButton>
								</MDBadge>
							</MDBox>
						)}
					</MDBox>
				</MDBox>
			</MDBox>
			<MDBox
				mt={3}
				style={{
					overflow: "auto",
					height: "60vh"
				}}
			>
				{loading && (
					<MDBox
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						style={{
							height: "100%"
						}}
					>
						<CircularProgress color="info" />
					</MDBox>
				)}
				{!loading && !source.extracted && (
					<MDBox
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						style={{
							height: "100%"
						}}
					>
						<MDTypography
							variant="h5"
							style={{ opacity: 0.5 }}
						>{`Les connaissances n'ont pas encore été extraites`}</MDTypography>
					</MDBox>
				)}
				{source.extracted && !loading && knowledges.length === 0 && (
					<MDBox
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						style={{
							height: "100%"
						}}
					>
						<MDTypography variant="h5" style={{ opacity: 0.5 }}>
							Aucune connaissance trouvée
						</MDTypography>
					</MDBox>
				)}

				{!loading && source.extracted && knowledges.length > 0 && (
					<Table>
						<TableBody>
							{knowledges.map((knowledge, index) => (
								<ExtractedRowLine
									key={index}
									knowledge={knowledge}
									selectedRows={selectedRows}
									handleSelectRow={handleSelectRow}
									fusionedKnowledgeUUID={fusionedKnowledgeUUID}
									setFusionedKnowledgeUUID={setFusionedKnowledgeUUID}
									source={source}
									setKnowledges={setKnowledges}
									getKnowledges={getKnowledges}
									setSelectedRows={setSelectedRows}
									setEditMode={setEditMode}
								/>
							))}
						</TableBody>
					</Table>
				)}
				{/* Actions menu */}
				<Menu
					id="simple-actions-menu"
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={() => setAnchorEl(null)}
				>
					<MenuItem onClick={mergeKnowledges} disabled={selectedRows.length < 2}>
						<Icon>merge</Icon>&nbsp;Fusionner
					</MenuItem>
					<MenuItem onClick={deleteKnowledges}>
						<Icon>delete</Icon>&nbsp;Supprimer
					</MenuItem>
				</Menu>
			</MDBox>
		</MDBox>
	);
}
