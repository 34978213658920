/* eslint-disable react/jsx-curly-brace-presence */
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { Checkbox, Icon, IconButton, Tooltip } from "@mui/material";
import MDBadge from "components/Basics/MDBadge";
import FaiqActions from "redux-react/actions/faiqActions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function KnowledgeDisplay({
	searched = false,
	selectedQuestion,
	knowledge,
	index,
	selectedKnowledges = [],
	selectKnowledge,
	setSelectedQuestion
}) {
	const dispatch = useDispatch();
	const profile = useSelector(state => state.profile);
	const isAlreadyAssociated = uuid => {
		return selectedQuestion.associations?.find(a => a?.knowledges?.includes(uuid));
	};

	const deleteAssociation = () => {
		dispatch(
			FaiqActions.deleteAssociation(
				profile.assistantID,
				{
					questionUUID: selectedQuestion.uuid,
					knowledgeUUID: knowledge.uuid,
					associationUUID: isAlreadyAssociated(knowledge.uuid)?.uuid
				},
				res => {
					setSelectedQuestion(res);
				}
			)
		);
	};

	return (
		<MDBox
			mt={2}
			p={1}
			borderRadius="md"
			style={{
				cursor: isAlreadyAssociated(knowledge.uuid) ? "inherit" : "pointer",
				userSelect: "none"
			}}
			bgColor={selectedKnowledges.includes(knowledge.uuid) ? "light" : "transparent"}
			onClick={e => {
				e.stopPropagation();
				if (!isAlreadyAssociated(knowledge.uuid)) {
					selectKnowledge(knowledge.uuid);
				}
			}}
		>
			<MDBox display="flex" alignItems="center" justifyContent="space-between">
				<MDBox display="flex" alignItems="center">
					{!searched && (
						<Tooltip placement="top" title={`Connaissance n°${knowledge.uuid}`}>
							<MDBadge color="dark" badgeContent={`#${index + 1}`} container size="md" />
						</Tooltip>
					)}
					<Tooltip
						placement="top"
						title={
							knowledge.score < selectedQuestion.minimumDistance
								? "En dessous du seuil"
								: "Au dessus du seuil"
						}
					>
						{searched ? (
							<MDBox display="flex" alignItems="center">
								<MDBadge size="small" color="info" badgeContent="Recherche" container />
							</MDBox>
						) : (
							<MDBox display="flex" alignItems="center" ml={1}>
								<MDBadge
									color={knowledge.score < selectedQuestion.minimumDistance ? "error" : "success"}
									badgeContent={`Score : ${knowledge.score.toFixed(4)}`}
									container
									size="md"
								/>
							</MDBox>
						)}
					</Tooltip>
				</MDBox>
				{!isAlreadyAssociated(knowledge.uuid) ? (
					<MDBox>
						<Checkbox checked={selectedKnowledges.includes(knowledge.uuid)} />
					</MDBox>
				) : (
					<Tooltip placement="top" title={`Supprimer l'association`}>
						<MDBox m={1}>
							<IconButton onClick={deleteAssociation}>
								<Icon>link_off</Icon>
							</IconButton>
						</MDBox>
					</Tooltip>
				)}
			</MDBox>
			<MDBox mt={1}>
				<MDTypography
					variant="body2"
					fontSize="small"
					dangerouslySetInnerHTML={{ __html: knowledge.content }}
					style={{
						whiteSpace: "pre-wrap"
					}}
				></MDTypography>
			</MDBox>
		</MDBox>
	);
}
