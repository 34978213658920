/* eslint-disable no-unreachable */
import MDBox from "components/Basics/MDBox";
import { Card, CardContent } from "@mui/material";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import FaiqActions from "redux-react/actions/faiqActions";

const LimitsPart = ({ settings }) => {
	const { profile } = useSelector(state => state);
	const [stats, setStats] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!settings) return;
		const onSuccessStat = res => {
			setStats(res.data);
		};

		dispatch(FaiqActions.getFaiqStats(profile.assistantID, onSuccessStat));
	}, []);

	if (!stats) return null;

	return (
		<>
			{/* Rate limits */}
			<MDBox display="flex" mt={3}>
				<MDBox flex="1" mr={2}>
					<Card>
						<CardContent>
							<MDTypography variant="h6">{i18n.t("STATS.tokenLimit")}</MDTypography>
							<MDTypography variant="body1">{`${
								settings.plan.config.maxTokenTrainingPlan
							} / ${i18n.t(`SETTINGS.PLAN.WORD.${settings.plan.billingPeriod}`)}`}</MDTypography>
						</CardContent>
					</Card>
				</MDBox>
				<MDBox flex="1">
					<Card>
						<CardContent>
							<MDTypography variant="h6">{i18n.t("STATS.questionsLimit")}</MDTypography>
							<MDTypography variant="body1">{`${settings.plan.config.questionsRate} / ${i18n.t(
								`SETTINGS.PLAN.WORD.${settings.plan.billingPeriod}`
							)}`}</MDTypography>
						</CardContent>
					</Card>
				</MDBox>
			</MDBox>
			{/* Tokens consumption */}
			<MDBox mt={3}>
				<Card>
					<CardContent>
						<MDBox>
							<MDTypography variant="h6">{i18n.t("STATS.trainingTokensConsumption")}</MDTypography>
							<MDTypography mt={2} variant="overline">
								{`${stats.tokensConsumption} / ${settings.plan.config.maxTokenTrainingPlan} tokens`}
							</MDTypography>
							<MDBox
								bgColor="light"
								variant="gradient"
								borderRadius="md"
								shadow="lg"
								className="stats_consumptionBackgroundLine"
							>
								<MDBox
									bgColor="info"
									variant="gradient"
									className="stats_consumptionLine"
									style={{
										width: `${
											(stats.tokensConsumption / settings.plan.config.maxTokenTrainingPlan) * 100
										}%`
									}}
								>
									<span className="stats_consumptionText">{`${Math.round(
										(stats.tokensConsumption / settings.plan.config.maxTokenTrainingPlan) * 100
									)}%`}</span>
								</MDBox>
							</MDBox>
						</MDBox>
					</CardContent>
				</Card>
			</MDBox>
			{/* API calls consumption */}
			<MDBox mt={3}>
				<Card>
					<CardContent>
						<MDBox>
							<MDTypography variant="h6">{`${i18n.t("STATS.questionsConsumption")} (${i18n.t(
								`SETTINGS.PLAN.WORD.${settings.plan.billingPeriod}`
							)} ${i18n.t("SETTINGS.PLAN.current")})`}</MDTypography>
							<MDTypography mt={2} variant="overline">
								{`${stats.callCount} / ${settings.plan.config.questionsRate} ${i18n.t(
									"STATS.questions"
								)}`}
							</MDTypography>
							<MDBox
								bgColor="light"
								variant="gradient"
								borderRadius="md"
								shadow="lg"
								className="stats_consumptionBackgroundLine"
							>
								<MDBox
									bgColor="info"
									variant="gradient"
									className="stats_consumptionLine"
									style={{
										width: `${(stats.callCount / settings.plan.config.questionsRate) * 100}%`
									}}
								>
									<span className="stats_consumptionText">{`${Math.round(
										(stats.callCount / settings.plan.config.questionsRate) * 100
									)}%`}</span>
								</MDBox>
							</MDBox>
						</MDBox>
					</CardContent>
				</Card>
			</MDBox>
		</>
	);
};

export default LimitsPart;
