/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Labels settings
 *
 * #######################################################@
 */
import "./style.css";

import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";

import { Divider, Icon } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import ConfirmDialog from "components/Custom/Dialogs/ConfirmDialog";
import MDBox from "components/Basics/MDBox";

import i18n from "i18n";
import AddLabelDialog from "./AddLabelDialog";
import EditLabelDialog from "./EditLabelDialog";

import ChartsActions from "redux-react/actions/chartsActions";
import SettingsActions from "redux-react/actions/settingsActions";
import { display } from "redux-react/reducers/snackBarReducer";

export default function SettingsLabels({ route }) {
	const { profile } = useSelector(state => state);
	const dispatch = useDispatch();

	const [paginedList, setPaginedList] = useState(null);
	const [addNewLabel, setAddNewLabel] = useState(false);
	const [editLabel, setEditLabel] = useState({
		open: false
	});
	// Confirm dialog state
	const [confirmDialog, setConfirmDialog] = useState({
		open: false,
		title: "",
		content: "",
		handleConfirm: () => {}
	});
	// Load pagined lists
	function loadPaginedList() {
		const onSuccess = res => {
			setPaginedList(res.paginedList);
		};

		dispatch(ChartsActions.getPaginedList(profile.assistantID, route, onSuccess));
	}
	// Handle save new label
	function handleSaveNewLabel({ code, label, color }) {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("SETTINGS.LABELS.SUCCESS.add"),
					type: "success"
				})
			);
			loadPaginedList();
		};
		dispatch(
			SettingsActions.newSetting(profile.assistantID, "label", { code, label, color }, onSuccess)
		);
	}
	// Handle edit label
	function handleEditLabel({ code, label, color }) {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("SETTINGS.LABELS.SUCCESS.edit"),
					type: "success"
				})
			);
			loadPaginedList();
		};
		dispatch(
			SettingsActions.editSetting(profile.assistantID, "label", { code, label, color }, onSuccess)
		);
	}
	// Handle delete label (open confirm dialog)
	function actionDeleteHandle(row) {
		const handleConfirmDialog = () => {
			setConfirmDialog({
				open: false
			});
			const onSuccess = res => {
				dispatch(
					display({
						message: i18n.t("SETTINGS.LABELS.SUCCESS.delete"),
						type: "success"
					})
				);
				loadPaginedList();
			};
			dispatch(
				SettingsActions.deleteSetting(profile.assistantID, "label", { code: row.code }, onSuccess)
			);
		};

		setConfirmDialog({
			open: true,
			title: `${i18n.t("SETTINGS.delete")} ${i18n.t("SETTINGS.LABELS.theLabel")} ${row.code}`,
			content: `${i18n.t("SETTINGS.deleteConfirmation")} ${i18n.t("SETTINGS.LABELS.theLabel")} ${
				row.code
			} ?`,
			handleConfirm: handleConfirmDialog
		});
	}

	// Handle edit label
	function actionEditHandle(row) {
		setEditLabel({
			open: true,
			...row
		});
	}

	useEffect(() => {
		setPaginedList(null);
		loadPaginedList();
	}, [profile.selectedAssistant.assistantID, route]);

	return (
		<DashboardLayout>
			<DashboardNavbar
				filters={[
					<MDBox display="flex">
						<MDButton
							style={{ height: "100%", marginRight: "0.75rem" }}
							variant="contained"
							color="info"
							onClick={() => setAddNewLabel(true)}
						>
							<Icon>add</Icon>&nbsp;{i18n.t("SETTINGS.LABELS.add")}
						</MDButton>
					</MDBox>
				]}
			/>
			<Divider />
			{paginedList &&
				paginedList.map((list, index) => {
					return (
						<DefaultDataTable
							canSearch
							key={index}
							table={list.data}
							display={list.request.attributesDisplay}
							actionEditHandle={actionEditHandle}
							actionDeleteHandle={actionDeleteHandle}
						/>
					);
				})}
			<AddLabelDialog
				open={addNewLabel}
				handleCloseDialog={() => setAddNewLabel(false)}
				handleSave={handleSaveNewLabel}
			/>
			<EditLabelDialog
				open={editLabel.open}
				handleCloseDialog={() =>
					setEditLabel({
						open: false
					})
				}
				handleSave={handleEditLabel}
				label={editLabel}
			/>
			<ConfirmDialog
				open={confirmDialog.open}
				title={confirmDialog.title}
				content={confirmDialog.content}
				handleClose={() => {
					setConfirmDialog({
						open: false
					});
				}}
				handleConfirm={confirmDialog.handleConfirm}
			/>
		</DashboardLayout>
	);
}
