/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Filter settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Icon,
	IconButton
} from "@mui/material";
import { useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import DatePresets from "components/Custom/DatePresets";
import i18n from "i18n";
import lod_ from "lodash";
import DictionaryMenu from "./DictionaryMenu";

const FilterRow = ({ type, filter, dictionary, removeItem, onEdit }) => {
	const [defaultValue, setDefaultValue] = useState("");
	/**
	 * Display name of dictionary element
	 */
	const getLabel = item => {
		return lod_.get(dictionary, item.dictionaryPath.replaceAll(".", ".items.")).label.fr;
	};

	return (
		<MDBox mt={1.5} bgColor="light" borderRadius="lg" shadow="lg" opacity={1} p={1}>
			<MDBox display="flex" alignItems="center" justifyContent="space-between">
				<MDBox>
					<MDTypography variant="overline">{filter.path.replaceAll(".", "/")}</MDTypography>
					<MDTypography variant="h6">{getLabel(filter)}</MDTypography>
				</MDBox>

				<IconButton
					onClick={() => {
						removeItem(filter);
					}}
				>
					<Icon fontSize="medium">close</Icon>
				</IconButton>
			</MDBox>
			{type.includes("date") && (
				<>
					<Divider />
					<MDBox display="flex" alignItems="center" justifyContent="space-between">
						<MDBox
							flex="1"
							mr={1}
							style={{
								whiteSpace: "nowrap"
							}}
						>
							<MDTypography variant="caption">
								{i18n.t("FILTERS.date.PRESETS.defaultValue")}
							</MDTypography>
						</MDBox>
						<MDInput
							flex="1"
							fullWidth
							disabled
							size="small"
							value={defaultValue ? i18n.t(`FILTERS.date.PRESETS.${defaultValue}`) : ""}
						/>
						<MDBox flex="1" ml={1}>
							<DatePresets
								fullHeight
								handleSelectPreset={({ interval, code }) => {
									setDefaultValue(code);
									onEdit({
										defaultValue: code
									});
								}}
							/>
						</MDBox>
					</MDBox>
				</>
			)}
		</MDBox>
	);
};

export default function AddFilterDialog({ open, handleCloseDialog, handleSave, dictionary }) {
	const [name, setName] = useState(null);
	const [filters, setFilters] = useState([]);

	const [anchorEl, setAnchorEl] = useState(null);

	const isDisabled = !name || filters.length < 1;
	/**
	 * Close dialog
	 */
	function close() {
		if (anchorEl) {
			setAnchorEl(null);
		} else {
			handleCloseDialog();
			setName(null);
			setFilters([]);
		}
	}
	/**
	 * Save filter
	 */
	function sumbit() {
		handleSave({ name, filters });
		close();
	}
	/**
	 * Remove filter from filters list
	 */
	function removeItem(item) {
		setFilters(filters.filter(f => f.path !== item.path));
	}
	/**
	 * Add filter to filters list
	 */
	const addItem = item => {
		let sourcePath = item.split(".")[0];
		let sourceObject = lod_.get(dictionary, sourcePath)?.source;
		let itemLabel = lod_.get(dictionary, item.replaceAll(".", ".items."))?.label?.fr;
		let dictionaryPath = lod_.clone(item);

		switch (sourceObject) {
			case "dataSources":
			case "custom":
				// If item is from dataSources, we need to use root path (si remove first part of path)
				item = item.split(".").slice(1).join(".");
				break;
			case "assistantConfig":
				// If item is from assistantConfig, we need to use dictionary path
				item = "dictionary." + item;
				break;
			default:
				break;
		}

		setAnchorEl(null);
		let same = filters.find(f => f.dictionaryPath === dictionaryPath);
		if (!same) {
			let filter = {
				path: item,
				dictionaryPath,
				source: sourceObject,
				label: itemLabel
			};
			setFilters([...filters, filter]);
		}
	};

	const editItem = (path, item) => {
		setFilters(filters => {
			let filter = filters.find(f => f.dictionaryPath === path);
			filter = { ...filter, ...item };
			return filters.map(f => (f.dictionaryPath === path ? filter : f));
		});
	};
	/**
	 * Main component
	 */
	return (
		<Dialog fullWidth maxWidth="md" open={open} onClose={close}>
			<DialogTitle>{i18n.t("SETTINGS.FILTERS.add")}</DialogTitle>
			<DialogContent>
				<MDBox mt={1}>
					<MDInput
						className="dialogInput"
						label={i18n.t("SETTINGS.FILTERS.name")}
						onChange={e => setName(e.target.value)}
					/>
				</MDBox>

				<MDBox mt={2} display="flex" alignItems="center" justifyContent="space-between">
					<MDTypography variant="h6">{i18n.t("SETTINGS.FILTERS.items")}</MDTypography>

					<MDButton variant="gradient" color="info" onClick={e => setAnchorEl(e.target)}>
						{i18n.t("SETTINGS.add")}
					</MDButton>
					<DictionaryMenu
						dictionary={dictionary}
						anchorEl={anchorEl}
						handleInsertText={addItem}
						handleClose={() => setAnchorEl(null)}
					/>
				</MDBox>

				{!filters.length && (
					<MDBox mt={1} display="flex" justifyContent="center">
						<MDTypography variant="overline">{i18n.t("SETTINGS.FILTERS.noItems")}</MDTypography>
					</MDBox>
				)}

				{filters.length > 0 && (
					<>
						{filters.map((filter, index) => {
							let sourceObject = lod_.get(
								dictionary,
								filter.dictionaryPath.replaceAll(".", ".items.")
							);
							if (!filter.path || !filter.label) {
								return (
									<MDBox
										key={index}
										mt={1.5}
										bgColor="error"
										borderRadius="lg"
										shadow="lg"
										opacity={1}
										p={1}
										display="flex"
										alignItems="center"
										justifyContent="space-between"
									>
										<MDBox>
											<MDTypography variant="h6">{`⚠️ Broken filter (${
												filter.label ?? filter.path
											})`}</MDTypography>
										</MDBox>

										<IconButton
											onClick={() => {
												removeItem(filter);
											}}
										>
											<Icon fontSize="medium">close</Icon>
										</IconButton>
									</MDBox>
								);
							}
							return (
								<FilterRow
									key={index}
									filter={filter}
									dictionary={dictionary}
									removeItem={removeItem}
									type={sourceObject.type}
									onEdit={item => {
										editItem(filter.dictionaryPath, item);
									}}
								/>
							);
						})}
					</>
				)}
			</DialogContent>
			<DialogActions>
				<MDButton variant="outlined" color="dark" onClick={close}>
					{i18n.t("SETTINGS.cancel")}
				</MDButton>
				<MDButton disabled={isDisabled} variant="contained" color="dark" onClick={sumbit}>
					{i18n.t("SETTINGS.add")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
}
