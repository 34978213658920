/* eslint-disable object-shorthand */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import { useEffect } from "react";
import MDTypography from "components/Basics/MDTypography";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

export default function Step1View({ datas = [], validStep }) {
	let columns = datas[0] ? Object.keys(datas[0]) : [];

	useEffect(() => {
		validStep();
	}, []);

	/**
	 * Display CSV to table
	 */
	return (
		<Table>
			<TableRow>
				{columns.map((column, index) => (
					<TableCell key={index}>
						<MDTypography variant="h6" component="td" key={index}>
							{column}
						</MDTypography>
					</TableCell>
				))}
			</TableRow>
			<TableBody>
				{datas.map((row, index) => (
					<TableRow key={index}>
						{columns.map((column, index) => (
							<TableCell key={index}>{row[column]}</TableCell>
						))}
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}
