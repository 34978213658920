/**
 * Main application knowledge
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";
import FaiqActions from "redux-react/actions/faiqActions";
import SubscriptionPart from "./components/Subscription";
import SettingsPart from "./components/Settings";
import LimitsPart from "./components/Limits";
import ModelsPart from "./components/Models";

/**
 *
 * @param {Object} route
 *  - key : actual page
 *  - type : type of page
 *  - icon : icon to display in navbar
 *  - route : actual route
 * @returns
 */
export default function SettingsAccountPage({ route }) {
	const { profile } = useSelector(state => state);
	const { permissions } = useSelector(state => state.permissions);
	const dispatch = useDispatch();

	const [copySetting, setCopySetting] = useState(null);
	const [settings, setSettings] = useState(null);

	// Load FAIQ settings
	function loadConfig() {
		const onSuccess = res => {
			setSettings(res.config);
			setCopySetting(res.config);
		};
		dispatch(FaiqActions.getFaiqConfig(profile.assistantID, onSuccess));
	}

	useEffect(() => {
		loadConfig();
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<Divider />

			{settings && (
				<>
					{/* Abonnement */}
					<SubscriptionPart permissions={permissions} settings={settings} />
					<Divider />
					{/* Limits */}
					<LimitsPart settings={settings} />
					<Divider />
					{/* Settings */}
					<SettingsPart
						route={route}
						copySetting={copySetting}
						setCopySetting={setCopySetting}
						settings={settings}
						setSettings={setSettings}
					/>
					{/* Model */}
					<ModelsPart />
				</>
			)}
			<MDBox mt={7}></MDBox>
		</DashboardLayout>
	);
}
