/* eslint-disable object-shorthand */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/**
 * Main application knowledge
 */
import "./style.css";
import MDBox from "components/Basics/MDBox";
import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import MDTypography from "components/Basics/MDTypography";
import { parseFilters, getLocalStorageBackValues } from "components/Custom/Filters/filters";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Icon,
	IconButton,
	Menu,
	MenuItem
} from "@mui/material";
import i18n from "i18n";
import lod_ from "lodash";
import { read, utils } from "xlsx";
import DefaultDataTable from "components/Custom/Tables/DefaultDataTable";
import MDButton from "components/Basics/MDButton";
import ChartsActions from "redux-react/actions/chartsActions";
import FaiqActions from "redux-react/actions/faiqActions";
import FormActions from "redux-react/actions/formAction";
import { display } from "redux-react/reducers/snackBarReducer";
import FormDictionaryDialog from "components/Custom/FormDictionary";
import RightPannel from "./components/RightPannel";

const DeleteDialog = ({ confirmDelete, closeDeleteHandle, deleteItem, selectedItem }) => {
	const [loading, setLoading] = useState(false);

	const deleteItemHandle = () => {
		setLoading(true);
		deleteItem();
	};

	useEffect(() => {
		setLoading(false);
	}, []);

	return (
		<Dialog open={confirmDelete} onClose={closeDeleteHandle}>
			<DialogTitle>{i18n.t(`FORMS.deleteElement`)}</DialogTitle>
			<DialogContent>{`${i18n.t("FORMS.LABELS.confirmDelete")} ${selectedItem.question}`}</DialogContent>
			<DialogActions>
				<MDButton onClick={closeDeleteHandle} color="info" variant="outlined">
					{i18n.t("FORMS.cancel")}
				</MDButton>
				<MDButton onClick={deleteItemHandle} disabled={loading} color="info" variant="contained">
					{i18n.t("FORMS.validate")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};

const ImportResults = ({ open, questions, handleClose }) => {
	const profile = useSelector(state => state.profile);
	const dispatch = useDispatch();

	const saveQuestions = () => {
		const onSuccess = res => {
			handleClose(true);
			dispatch(
				display({
					type: "success",
					message: `${questions.length} questions ont été ajoutées avec succès`
				})
			);
		};

		dispatch(FaiqActions.saveQuestions(profile.assistantID, questions, onSuccess));
	};

	return (
		<Dialog fullWidth maxWidth="md" open={open} onClose={() => handleClose()}>
			<DialogTitle>{`${questions.length}`} Questions trouvées</DialogTitle>
			<DialogContent>
				{questions.map((question, index) => {
					return (
						<MDBox key={index} mt={1}>
							<MDTypography variant="body2" fontSize="medium">
								{question}
							</MDTypography>
						</MDBox>
					);
				})}
			</DialogContent>
			<DialogActions>
				<MDButton variant="contained" onClick={handleClose}>
					{i18n.t("SETTINGS.close")}
				</MDButton>
				<MDButton variant="contained" color="info" onClick={saveQuestions}>
					{i18n.t("SETTINGS.add")}
				</MDButton>
			</DialogActions>
		</Dialog>
	);
};

/**
 * Main component
 */
export default function TestingPage({ route }) {
	// Form
	const [addNewItem, setAddNewItem] = useState(false);
	const [valuesForm, setValuesForm] = useState({});
	const [formBuild, setFormBuild] = useState({});
	const [contextDictionary, setContextDictionary] = useState({});
	const [PI, setPI] = useState(false);
	const [empty, setEmpty] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);

	const [selectedItem, setSelectedItem] = useState({});
	const [selectedTarget, setSelectedTarget] = useState("");
	const [routeDict, setRouteDict] = useState("");
	const [selectedId, setSelectedId] = useState(null);

	// Page
	const { profile, filters } = useSelector(state => state);
	const dispatch = useDispatch();
	// Main chart
	const [rawCharts, setRawCharts] = useState({});
	const [pageFilters, setPageFilters] = useState([]);
	const [paginedList, setPaginedList] = useState(null);

	const [reloadTable, setReloadTable] = useState(false);

	const [menuRef, setMenuRef] = useState(null);
	const [menuValues, setMenuValues] = useState({});

	const [openPannel, setOpenPannel] = useState(false);
	const [selectedQuestion, setSelectedQuestion] = useState(null);
	const rightPannelContainer = useRef(null);

	// Import results dialog
	const [importResults, setImportResults] = useState({
		open: false,
		questions: []
	});

	/* Load chart */
	function loadPaginedList() {
		const onSuccess = res => {
			setPageFilters(res.filters);
			setRawCharts(res.charts);
			setReloadTable(!reloadTable);
		};

		dispatch(ChartsActions.getCharts(profile.assistantID, route, onSuccess));
	}
	/* Build charts */
	function buildRawCharts() {
		if (lod_.isEmpty(rawCharts)) return;
		// Success handler : set charts data
		const onSuccess = res => {
			setPaginedList(res.charts[0]);
		};
		// Build charts
		let mandatoryFilters = pageFilters.map(filter => filter.attribute);
		dispatch(ChartsActions.buildRawCharts(rawCharts, filters, mandatoryFilters, onSuccess));
	}

	const handleImportFile = event => {
		try {
			let file = event.target.files[0];
			const reader = new FileReader();
			// 1- Need to read the file
			reader.onload = e => {
				const wb = read(e.target.result);
				const sheets = wb.SheetNames;
				const data = utils.sheet_to_json(wb.Sheets[sheets[0]]);

				let questions = [];

				for (let row of data) {
					let values = Object.values(row);
					questions = [...questions, ...values];
				}

				setImportResults({
					open: true,
					questions: questions
				});
			};
			reader.readAsArrayBuffer(file);

			event.target.value = null;
		} catch (error) {
			//
		}
	};

	const testFAIQ = uuid => {
		dispatch(
			display({
				message: i18n.t(`FAIQ.TESTING.waitingMessageTesting`),
				type: "success"
			})
		);

		dispatch(
			FaiqActions.testFAIQ(profile.assistantID, uuid, res => {
				dispatch(
					display({
						message: i18n.t(`FAIQ.TESTING.resultMessageTesting`),
						type: "success"
					})
				);
				setReloadTable(!reloadTable);
				if (!lod_.isEmpty(res)) {
					setSelectedQuestion(res);
					setOpenPannel(true);
				}
			})
		);
	};

	const closePannel = () => {
		setOpenPannel(false);
		setSelectedQuestion(null);
	};
	// Form
	const actionEditHandle = (items, target) => {
		const onSuccess = res => {
			setAddNewItem(true);
			setEmpty(false);
			setValuesForm(res.valueDictionary);
			setFormBuild(res.formBuilderDictionary);
			setContextDictionary(res.dictionary);
			setPI(false);
			setSelectedId(items._id);
			setSelectedTarget(target);
			setRouteDict(route.form.routeDictionary);
			setReloadTable(!reloadTable);
		};
		dispatch(FormActions.getItemByID(items._id, target, route.form.routeDictionary, onSuccess));
	};

	const actionEditEmptyHandle = () => {
		const onSuccess = res => {
			setAddNewItem(true);
			setEmpty(true);
			setValuesForm(res.valueDictionary);
			setFormBuild(res.formBuilderDictionary);
			setContextDictionary(res.dictionary);
			setPI(true);
			setSelectedTarget(route.form.collectionDefault);
			setRouteDict(route.form.routeDictionary);
			setReloadTable(!reloadTable);
		};

		dispatch(
			FormActions.getItemEmpty(route.form.collectionDefault, route.form.routeDictionary, onSuccess)
		);
	};

	const actionDeleteHandle = (item, target) => {
		setConfirmDelete(true);
		setSelectedItem(item);
		setSelectedTarget(target);
	};

	const closeDeleteHandle = () => {
		setConfirmDelete(false);
	};

	const deleteItem = () => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.deleteSuccess"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			setConfirmDelete(false);
		};
		if (selectedItem._id) {
			dispatch(
				FormActions.deleteItem(
					selectedItem._id,
					selectedTarget,
					{
						delete: true
					},
					onSuccess
				)
			);
		} else {
			setConfirmDelete(false);
			dispatch(
				display({
					message: i18n.t("FORMS.deleteError"),
					type: "error"
				})
			);
		}
	};

	const actionSaveOrCreateItem = (values, unique, callback, rolesProfileUser) => {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("FORMS.addEmpty"),
					type: "success"
				})
			);
			setReloadTable(!reloadTable);
			setAddNewItem(false);
			callback(true);
		};
		let data = { values, target: selectedTarget, unique };
		if (empty) {
			data = { ...data, rolesProfileUser };
			dispatch(
				FormActions.addItemEmpty(data, onSuccess, err => {
					callback(false);
				})
			);
		} else {
			dispatch(
				FormActions.updateItem(selectedId, data, onSuccess, err => {
					callback(false);
				})
			);
		}
	};

	useEffect(() => {
		buildRawCharts();
	}, [rawCharts]);

	useEffect(() => {
		setPaginedList(null);
		loadPaginedList();
	}, [profile.selectedAssistant.assistantID, route]);

	return (
		<DashboardLayout>
			<MDBox>
				<DashboardNavbar
					filters={[
						<MDBox
							display="flex"
							justifyContent="space-between"
							style={{
								width: "100%"
							}}
						>
							{/* First container */}
							<MDBox display="flex">
								{parseFilters(profile.assistantID, route.route, pageFilters, filters, dispatch)}
								<MDButton
									style={{ height: "100%", marginRight: "0.75rem" }}
									variant="contained"
									color="info"
									onClick={actionEditEmptyHandle}
								>
									<Icon>add</Icon>&nbsp;
									{i18n.t("SETTINGS.add")}
								</MDButton>
								<MDButton
									style={{ height: "100%" }}
									variant="contained"
									component="label"
									color="info"
								>
									<input
										type="file"
										name="file"
										accept={[
											".xls",
											".xlsx",
											".xlsm",
											".xlsb",
											".xlt",
											".xltx",
											".xltm",
											".xlam",
											".ods",
											".csv"
										]}
										hidden
										onChange={handleImportFile}
									></input>
									<Icon>publish</Icon>&nbsp;{i18n.t("SETTINGS.import")}
								</MDButton>
							</MDBox>
							{/* Second container */}
							<MDBox display="flex" flexDirection="column">
								<MDButton
									variant="contained"
									color="error"
									onClick={() => {
										testFAIQ("all");
									}}
								>
									<Icon>adjust</Icon>&nbsp;{i18n.t("SETTINGS.test")}
								</MDButton>
							</MDBox>
						</MDBox>
					]}
				/>
			</MDBox>
			{paginedList && (
				<MDBox
					className="pageContentContainer"
					style={{
						display: openPannel ? "flex" : "block"
					}}
				>
					<MDBox flex="1">
						<DefaultDataTable
							onRowClick={row => {
								if (!row.tested) {
									return;
								}

								setSelectedQuestion(row);
								setOpenPannel(true);
							}}
							isSelectedRow={values => {
								if (!selectedQuestion) {
									return false;
								}

								return values.uuid === selectedQuestion?.uuid;
							}}
							dictionary={paginedList.dictionary}
							canSearch
							table={paginedList.data}
							display={paginedList.request.attributesDisplay}
							pagination={paginedList.pagination}
							list={paginedList}
							actions={[
								<IconButton
									handleclick={({ values }, e) => {
										setMenuValues(values);
										setMenuRef(e.currentTarget);
										e.stopPropagation();
									}}
								>
									<Icon fontSize="medium">more_vert</Icon>
								</IconButton>
							]}
							reloadTable={reloadTable}
							filters={getLocalStorageBackValues(
								profile.selectedAssistant.assistantID,
								route.route,
								filters
							)}
						/>
					</MDBox>

					{openPannel && selectedQuestion && (
						<MDBox className="rightPannelContainer" ref={rightPannelContainer}>
							<RightPannel
								selectedQuestion={selectedQuestion}
								closePannel={closePannel}
								setSelectedQuestion={setSelectedQuestion}
							/>
						</MDBox>
					)}
				</MDBox>
			)}
			{/* Menu actions selection */}
			<Menu
				open={Boolean(menuRef)}
				anchorEl={menuRef}
				onClose={() => {
					setMenuRef(null);
				}}
			>
				{/* Options */}
				<MenuItem disabled style={{ opacity: 1 }}>
					<MDTypography variant="caption">{i18n.t(`FAIQ.TESTING.edition`)}</MDTypography>
				</MenuItem>
				<MenuItem
					onClick={() => {
						actionEditHandle(menuValues, paginedList.request.collection);
						setMenuRef(null);
					}}
				>
					<Icon fontSize="medium">edit</Icon>&nbsp;{i18n.t("SETTINGS.edit")}
				</MenuItem>
				<MenuItem
					onClick={() => {
						if (menuValues?.associations?.length > 0) {
							dispatch(
								display({
									message: "Veuillez supprimer les assiocations avant de supprimer la question",
									type: "warning"
								})
							);
							setMenuRef(null);
						} else {
							actionDeleteHandle(menuValues, paginedList.request.collection);
							setMenuRef(null);
						}
					}}
				>
					<Icon fontSize="medium">delete</Icon>&nbsp;{i18n.t("SETTINGS.delete")}
				</MenuItem>
				{/*  */}
				<Divider />
				<MenuItem disabled style={{ opacity: 1 }}>
					<MDTypography variant="caption">{i18n.t(`FAIQ.TESTING.actions`)}</MDTypography>
				</MenuItem>
				<MenuItem
					onClick={() => {
						testFAIQ(menuValues.uuid);
						setMenuRef(null);
					}}
				>
					<Icon fontSize="medium">adjust</Icon>&nbsp;{i18n.t(`FAIQ.TESTING.testing`)}
				</MenuItem>
				{/*  */}
				<Divider />
				<MenuItem disabled style={{ opacity: 1 }}>
					<MDTypography variant="caption">{i18n.t(`FAIQ.TESTING.visualisation`)}</MDTypography>
				</MenuItem>
				<MenuItem
					disabled={!menuValues.tested}
					onClick={() => {
						setSelectedQuestion(menuValues);
						setOpenPannel(true);
						setMenuRef(null);
					}}
				>
					<Icon fontSize="medium">visibility</Icon>&nbsp;{i18n.t(`FAIQ.TESTING.knowledges`)}
				</MenuItem>
			</Menu>
			{/* Import dialog */}
			<ImportResults
				open={importResults.open}
				questions={importResults.questions}
				handleClose={(reload = false) => {
					setImportResults({
						open: false,
						questions: []
					});

					if (reload) {
						setReloadTable(!reloadTable);
					}
				}}
			/>
			{/* Form */}
			{addNewItem && (
				<FormDictionaryDialog
					open={addNewItem}
					route={route}
					handleCloseDialog={() => setAddNewItem(false)}
					handleSave={(values, unique, callback, rolesProfileUser) =>
						actionSaveOrCreateItem(values, unique, callback, rolesProfileUser)
					}
					valuesDictionary={valuesForm}
					formBuildDictionary={formBuild}
					contextDictionary={contextDictionary}
					PIaccess={PI}
					isEmpty={empty}
					target={selectedTarget}
					selectedId={selectedId}
					routeDict={routeDict}
				/>
			)}
			{confirmDelete && (
				<DeleteDialog
					confirmDelete={confirmDelete}
					closeDeleteHandle={closeDeleteHandle}
					deleteItem={deleteItem}
					selectedItem={selectedItem}
				/>
			)}
			<MDBox mt={7}></MDBox>
		</DashboardLayout>
	);
}
