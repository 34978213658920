/* eslint-disable object-shorthand */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import MDBox from "components/Basics/MDBox";
import InformationCard from "pages/settings/charts/steps/components/InformationCard";

export default function Step1Choice({ selectMethod }) {
	return (
		<MDBox
			sx={{ ml: 30, mr: 30 }}
			style={{
				height: "100%"
			}}
		>
			<MDBox
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				style={{
					height: "100%"
				}}
			>
				<MDBox
					style={{
						width: "100%"
					}}
				>
					<InformationCard
						element={{
							title: "Analyse du fichier",
							description: "Analyser le fichier pour en extraire les données"
						}}
						onClick={() => selectMethod("unstructured")}
					/>
				</MDBox>
				<MDBox
					style={{
						width: "100%"
					}}
				>
					<InformationCard
						element={{
							title: "Insertion de connaissances",
							description:
								"Inserer chaque ligne du fichier en connaissances pour enrichir les données"
						}}
						onClick={() => selectMethod("csv")}
					/>
				</MDBox>
			</MDBox>
		</MDBox>
	);
}
