/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import { Icon, IconButton, Tooltip } from "@mui/material";
import lod_ from "lodash";

const { default: MDButton } = require("components/Basics/MDButton");
const { default: csvDownload } = require("json-to-csv-export");

const DownloadCSV = ({ data, title, list = {} }) => {
	function parseCSV(data) {
		if (data.datasets) {
			let dataset = data.datasets[0];
			let res = [];
			for (let i = 0; i < dataset.data.length; i++) {
				res.push({
					label: data.labels[i],
					value: dataset.data[i]
				});
			}

			return {
				data: res,
				headers: ["Label", "Valeur"]
			};
		} else if (data.rows && data.columns) {
			if (data.totalRow) {
				data.rows.push(data.totalRow);
			}
			let res = [];
			let headers = [];
			data.columns.map((item, colIndex) => {
				headers.push(item.Header);

				data.rows.map((row, index) => {
					let exist = true;
					let obj = res.find((r, i) => i === index);

					if (!obj) {
						obj = {};
						exist = false;
					}
					let value = row[item.accessor] ?? 0;
					obj[item.accessor] = value;

					if (!exist) {
						res.push(obj);
					}
				});
			});

			return {
				data: res,
				headers
			};
		} else {
			if (lod_.isEmpty(data) || lod_.isEmpty(list)) {
				return { data: [], headers: [] };
			}

			let map = {};

			let displayAttribute = list.request.attributesDisplay;

			for (let attribute of displayAttribute) {
				let item = list.data?.columns?.find(col => col.accessor === attribute);
				map[attribute] = item?.Header || attribute;
			}

			let res = [];
			let keys = lod_.keys(map);

			for (let row of data) {
				let obj = {};
				for (let key of keys) {
					let item = row[key];
					if (lod_.isObject(item)) {
						item = JSON.stringify(item);
					}
					obj[map[key]] = item;
				}
				res.push(obj);
			}

			return {
				data: res,
				headers: displayAttribute.map(attr => map[attr])
			};
		}
	}

	return (
		<Tooltip arrow placement="top" title="Télécharger en CSV">
			<IconButton
				size="small"
				onClick={() => {
					let csv = parseCSV(data);
					csvDownload({
						data: csv.data,
						filename: `${title}.csv`,
						headers: csv.headers
					});
				}}
			>
				<Icon>download</Icon>
			</IconButton>
		</Tooltip>
	);
};

export default DownloadCSV;
