/* eslint-disable react/jsx-props-no-spreading */
import { Tooltip } from "@mui/material";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SignalWifi0BarIcon from "@mui/icons-material/SignalWifi0Bar";
import SignalWifi1BarIcon from "@mui/icons-material/SignalWifi1Bar";
import SignalWifi2BarIcon from "@mui/icons-material/SignalWifi2Bar";
import SignalWifi3BarIcon from "@mui/icons-material/SignalWifi3Bar";
import SignalWifi4BarIcon from "@mui/icons-material/SignalWifi4Bar";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import UpdateIcon from "@mui/icons-material/Update";
import interrogation from "./assets/images/interrogation.svg";

// Display  the right sentiment Icon
export const getSentimentIcon = sentiment => {
	switch (sentiment) {
		case "negative":
			return <SentimentVeryDissatisfiedIcon fontSize="small" style={{ color: "red" }} />;
		case "neutral":
			return <SentimentSatisfiedIcon fontSize="small" style={{ color: "black" }} />;
		case "positive":
			return <SentimentSatisfiedAltIcon fontSize="small" style={{ color: "green" }} />;
		default:
			return <SentimentSatisfiedIcon fontSize="small" style={{ color: "black" }} />;
	}
};

// Display  the right cellular Icon
export const getConfidenceIcon = score => {
	if (!score) {
		return <SignalWifi0BarIcon fontSize="small" style={{ color: "grey" }} />;
	} else if (score < 0.25) {
		return <SignalWifi1BarIcon fontSize="small" style={{ color: "red" }} />;
	} else if (score <= 0.5) {
		return <SignalWifi2BarIcon fontSize="small" style={{ color: "orange" }} />;
	} else if (score < 0.75) {
		return <SignalWifi3BarIcon fontSize="small" style={{ color: "blue" }} />;
	} else return <SignalWifi4BarIcon fontSize="small" style={{ color: "green" }} />;
};

/**
 * @returns for a channel code it will return the channel logo or logoURL if exists, for a channel type or sourceMarketplace it returns the right logo
 */
export const GetChannelImage = channel => {
	let channelToDisplay = "";
	switch (channel) {
		case "WB":
			channelToDisplay = "WB";
			break;
		case "FB":
			channelToDisplay = "FB";
			break;
		case "IS":
			channelToDisplay = "IS";
			break;
		case "WS":
			channelToDisplay = "WS";
			break;
		case "FD":
			channelToDisplay = "FD";
			break;
		case "ZD":
			channelToDisplay = "ZD";
			break;
		case "CR":
			channelToDisplay = "CR";
			break;
		case "MAIL":
		case "ML":
			channelToDisplay = "ML";
			break;
		case "MK":
			channelToDisplay = "MK";
			break;
		case "TG":
			channelToDisplay = "TG";
			break;
		case "WA":
			channelToDisplay = "WA";
			break;
		case "SMS":
			channelToDisplay = "SMS";
			break;
		case "PH":
			channelToDisplay = "SMS";
			break;
		case "PK":
		case "VEEPEE":
			channelToDisplay = "VEEPEE";
			break;
		case "GALERIES LAFAYETTE":
			channelToDisplay = "GALERIES LAFAYETTE";
			break;
		case "AV":
		case "AVIS VERIFIES":
			channelToDisplay = "AVIS VERIFIES";
			break;
		case "ZA":
		case "ZALANDO":
			channelToDisplay = "ZALANDO";
			break;
		case "LR":
		case "LA REDOUTE":
			channelToDisplay = "LR";
			break;
		case "AMAZON":
		case "AZ":
			channelToDisplay = "AZ";
			break;
		case "LC":
			channelToDisplay = "LC";
			break;
		case "PP":
			channelToDisplay = "PP";
			break;
		case "FO":
			channelToDisplay = "FO";
			break;
		case "RMS":
			channelToDisplay = "RMS";
			break;
		case "MAO":
			channelToDisplay = "MANOMANO";
			break;
		case "AZ-V":
			channelToDisplay = "AZ";
			break;
		case "DECATHLON":
			channelToDisplay = "DECATHLON";
			break;
		case "SHOWROOM PRIVE":
			channelToDisplay = "SHOWROOMPRIVE";
			break;
		case "DISTRI-ARTISAN":
			channelToDisplay = "DISTRIARTISAN";
			break;
		case "LEROY MERLIN":
			channelToDisplay = "LEROY MERLIN";
			break;
		case "BHV":
			channelToDisplay = "BHV";
			break;
		case "SARENZA":
			channelToDisplay = "SARENZA";
			break;
		default:
			channelToDisplay = "";
	}
	return (
		"https://faibrik-public-prod1.s3.eu-west-3.amazonaws.com/logo_" + channelToDisplay + ".png"
	);
};

// Display  the right format Date
export function formatDate(date) {
	if (date) {
		let d1 = date.substring(0, 10);
		let d2 = date.substring(11, 16);
		return d1 + " " + d2;
	} else {
		return "";
	}
}

export const CustomFlag = ({ code, ...rest }) => {
	const URL = "https://faibrik-public-prod1.s3.eu-west-3.amazonaws.com/flags/";
	if (!code) {
		return <img src={interrogation} alt="not-found" {...rest} style={{ width: "20px" }}></img>;
	} else {
		switch (code) {
			case "PT-BR": {
				code = "PT";
				break;
			}
			case "ZH-CN": {
				code = "ZH";
				break;
			}
			case "ZH-TH": {
				code = "ZH";
				break;
			}

			default:
				break;
		}
		return (
			<Tooltip title={code} placement="left">
				<img
					src={`${URL}${code.toUpperCase()}.png`}
					alt={code.toUpperCase()}
					{...rest}
					onError={({ currentTarget }) => {
						currentTarget.onerror = null; // prevents looping
						currentTarget.src = interrogation;
					}}
				></img>
			</Tooltip>
		);
	}
};

export const getTypeIcon = type => {
	switch (type) {
		case "intent":
			return <SignalWifi3BarIcon fontSize="small" style={{ color: "gray" }} />;

		case "sentiment":
			return <SentimentSatisfiedIcon fontSize="small" style={{ color: "gray" }} />;

		default:
			return `${type}`;
	}
};

export const getValueIcon = type => {
	switch (type) {
		case "positive":
			return <ThumbUpIcon fontSize="small" style={{ color: "green" }} />;

		case "negative":
			return <ThumbDownIcon fontSize="small" style={{ color: "red" }} />;

		case "change":
			return <UpdateIcon fontSize="small" style={{ color: "red" }} />;

		default:
			return `Unknown ${type}`;
	}
};
