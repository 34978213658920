/* eslint-disable prefer-destructuring */
/* eslint-disable no-lonely-if */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { useEffect, useState } from "react";
import lod_ from "lodash";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import MDButton from "components/Basics/MDButton";
import MDInput from "components/Basics/MDInput";
import DictionaryButton from "components/Custom/DictionaryButton";
import {
	Chip,
	Divider,
	FormControl,
	Icon,
	IconButton,
	InputAdornment,
	ListSubheader,
	Menu,
	MenuItem,
	Select,
	Tooltip
} from "@mui/material";
import i18n from "i18n";
import FiltersTable from "./FiltersTable";
import C from "../constants";

/**
 * Parse dictionary to get available items by type
 * @param {[String]} value like : ["number"]
 */
export function parseTypeDictionary(arrayValues, dictionary) {
	let availableItems = {};
	// Map key of object
	for (let key in dictionary) {
		let actualLevel = dictionary[key];
		if (actualLevel.type === "level") {
			// Branch
			let result = parseTypeDictionary(arrayValues, actualLevel.items);
			// If we have some results we add the branch to the return
			if (!lod_.isEmpty(result)) {
				let copy = lod_.cloneDeep(actualLevel);
				copy.items = result;
				availableItems[key] = copy;
			}
		} else {
			// When item is sheet (end of the branch)
			if (arrayValues.includes(actualLevel.type)) {
				availableItems[key] = actualLevel;
			}
		}
	}
	return availableItems;
}

const FieldInput = ({
	info = null,
	getLabelFromDictionary,
	request,
	disabled = false,
	title,
	field,
	dictionary,
	updateRequest,
	required = false
}) => {
	return (
		<MDBox mt={2}>
			<MDBox display="flex" alignItems="center">
				<MDTypography variant="h6">
					{title}
					{required && <span className="mandatoryField">*</span>}
				</MDTypography>
				{info && (
					<MDBox ml={1} display="flex" alignItems="center">
						<Tooltip title={info} placement="top">
							<Icon>info</Icon>
						</Tooltip>
					</MDBox>
				)}
			</MDBox>
			<MDBox display="flex" alignItems="center">
				<MDInput
					className="dialogInput"
					value={getLabelFromDictionary(lod_.get(request, field))}
					onChange={() => {}}
					disabled
					style={{
						pointerEvents: "none"
					}}
					InputProps={
						!required
							? {
									endAdornment: (
										<InputAdornment
											position="end"
											style={{
												pointerEvents: "auto"
											}}
										>
											<IconButton
												onClick={() => {
													updateRequest(field, null);
												}}
											>
												<Icon>close</Icon>
											</IconButton>
										</InputAdornment>
									)
								}
							: {}
					}
				/>
				<MDBox ml={1}>
					<DictionaryButton
						disabled={disabled}
						dictionary={dictionary}
						handleClick={e => updateRequest(field, e)}
					/>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};
/**
 * Main component
 */
const DataChoiceStep = ({
	chart,
	handleSubmitDatas,
	handleSubmitFilters,
	handleSubmitOptions,
	collections,
	isStepValid,
	validStep
}) => {
	function getDictionaryCollection(collection) {
		return collections.find(c => c.collection === collection)?.dictionary;
	}

	function mapFiltersDictionary(filters) {
		let array = [];
		let keys = Object.keys(filters);
		for (let key of keys) {
			let method = Object.keys(filters[key])[0];
			let value = filters[key][method];

			let dic = getDictionaryCollection(chart.request.collection);
			if (dic) {
				dic = dic[chart.request.collection];
			}
			let dicObject = lod_.get(dic.items, key.replaceAll(".", ".items."));
			if (dicObject) {
				let filter = {
					filter: dicObject,
					method,
					value,
					name: key
				};
				array.push(filter);
			}
		}
		return array;
	}

	const [request, setRequest] = useState(chart.request);
	const [filters, setFilters] = useState({});
	const [options, setOptions] = useState(chart.options);
	const [dictionary, setDictionary] = useState(
		chart?.request?.collection ? getDictionaryCollection(chart.request?.collection) : {}
	);
	// anchors
	const [binningMenuEl, setBinningMenuEl] = useState(null);
	// manage binning state
	const [binning, setBinning] = useState(chart.request?.binning || []);

	const [filtersArray, setFiltersArray] = useState(
		chart.filters ? mapFiltersDictionary(chart.filters) : []
	);

	const DELETE_MAP = {
		collection: [
			"computeMethod",
			"attribute",
			"group",
			"binning",
			"row",
			"col",
			"filterDateAttribute",
			"dateAttribute",
			"filters"
		],
		computeMethod: ["attribute"]
	};

	const VALID_MAP = {
		oneValue: [],
		oneDimension: [],
		proportion: [],
		crossTable: ["row", "col"],
		timeSeries: ["dateAttribute", "binning"]
	};

	// Handle when choosing a data source
	const updateCollection = collection => {
		let toDelete = DELETE_MAP.collection;

		setRequest(req => {
			let nReq = {
				...req,
				collection: collection.collection
			};

			for (let key of toDelete) {
				delete nReq[key];
			}

			return nReq;
		});
		setDictionary(collection.dictionary);
	};
	// Handle when choosing computing method
	const updateMethod = method => {
		setRequest(req => {
			let nReq = {
				...req,
				computeMethod: method.code
			};
			return nReq;
		});

		setOptions(prevOptions => {
			let nOptions = { ...prevOptions };
			delete nOptions.formatDate;
			return nOptions;
		});
	};

	const getLabelFromDictionary = attribute => {
		let dic = dictionary[request.collection];
		if (dic) {
			let dicObject = lod_.get(dic.items, attribute);
			if (dicObject) {
				return dicObject.label.fr;
			}
		}
		return i18n.t("SETTINGS.CHARTS.NEW.noSelection");
	};
	// Default update function for all other steps
	const updateRequest = (path, value) => {
		// Case when user delete a field
		if (!value) {
			setRequest(req => {
				let nReq = {
					...req
				};
				delete nReq[path];
				return nReq;
			});
			return;
		}

		let fieldInDic = value.split(".");

		if (fieldInDic.length > 1) {
			fieldInDic = value.split(".").slice(1).join(".");
		} else {
			fieldInDic = fieldInDic[0];
		}
		// Special case for attribute field
		if (path === "attribute") {
			let realPath = value.replaceAll(".", ".items.");
			let dicObject = lod_.get(dictionary, realPath);
			if (dicObject && dicObject.type === "timestamp") {
				setOptions({
					...options,
					formatDate: true
				});
			} else {
				setOptions(prevOptions => {
					let nOptions = { ...prevOptions };
					delete nOptions.formatDate;
					return nOptions;
				});
			}
		}
		// Special case for row
		if (path === "row") {
			if (chart.type === "timeSeries") {
				setOptions(prevOptions => {
					let nOptions = { ...prevOptions };
					nOptions.legend = request.row;
					return nOptions;
				});
			}
		}

		setRequest(req => {
			let nReq = {
				...req,
				[path]: fieldInDic
			};
			return nReq;
		});
	};
	/**
	 * Binning functions
	 */
	// Handle when user add an element to binning
	const updateBinning = code => {
		let newBinning = lod_.clone(binning);
		newBinning.push(code);
		setBinning(newBinning);
	};
	// Handle when user remove an element to binning
	const removeBinning = index => {
		let newBinning = lod_.clone(binning);
		if (newBinning.length < index) return;
		newBinning.splice(index, 1);
		setBinning(newBinning);
	};
	/*
	 * Filters functions
	 */
	// When user update a filter, save it
	const onChangeValue = ({ name, filter, method, value }) => {
		if (method && value) {
			let mongoFilter = {
				[name]: {
					[method]: value
				}
			};

			let newFilters = {
				...filters,
				...mongoFilter
			};

			let updatedFilter = {
				filter,
				name,
				method,
				value
			};

			let updatedFiltersArray = filtersArray.map(f => {
				if (f.name === name) {
					return updatedFilter;
				} else {
					return f;
				}
			});

			setFiltersArray(updatedFiltersArray);
			setFilters(newFilters);
		}
	};
	// When user want to add a filter
	const handleAddFilter = filter => {
		let realPath = filter.replaceAll(".", ".items.");
		let dicObject = lod_.get(dictionary, realPath);
		let name = filter.split(".")[filter.split(".").length - 1];
		// user can add only one filter by attribute
		let existInArray = filtersArray.find(f => f.name === name);

		if (dicObject && !existInArray) {
			let copy = [
				...filtersArray,
				{
					filter: dicObject,
					name
				}
			];

			setFiltersArray(copy);
		}
	};
	// When user want to remove a filter
	const handleRemoveFilter = name => {
		let filteredArray = filtersArray.filter(f => f.name !== name);
		setFiltersArray(filteredArray);

		let filteredFilters = lod_.cloneDeep(filters);
		delete filteredFilters[name];

		setFilters(filteredFilters);
	};

	function checkStepValidity() {
		// Check is all is valid
		let isValid = true;
		let chartMap = VALID_MAP[chart.type] || [];

		for (let key of chartMap) {
			if (!request[key]) {
				isValid = false;
			}
			if (lod_.isArray(request[key]) && request[key].length === 0) {
				isValid = false;
			}
		}

		if (chart.request.computeMethod !== "COUNT" && !request.attribute) {
			isValid = false;
		}

		if (isValid && chart.type === "timeSeries" && !request.row) {
			setOptions(prevOptions => {
				let nOptions = { ...prevOptions };
				nOptions.legend = false;
				return nOptions;
			});
		}

		validStep(isValid);
	}
	// When user add / remove binning
	useEffect(() => {
		setRequest(req => {
			return {
				...req,
				binning
			};
		});
	}, [binning]);
	// Used when user change data source / method / attribute / group
	useEffect(() => {
		handleSubmitDatas(request);
		checkStepValidity();
	}, [request]);
	// Used when user add / remove filter
	useEffect(() => {
		handleSubmitFilters(filters);
	}, [filters]);
	// Used when user add / remove options
	useEffect(() => {
		handleSubmitOptions(options);
	}, [options]);
	// On page load
	useEffect(() => {
		checkStepValidity();
	}, []);

	const COLLECTIONS_SOURCE_MAP = {
		custom: i18n.t("SETTINGS.CHARTS.dictionariesDatas"),
		dataSources: i18n.t("SETTINGS.CHARTS.faibrikDatas")
	};

	function mapMenuCollection(collections) {
		let menu = {};

		for (let collection of collections) {
			if (collection.source) {
				if (!menu[collection.source]) {
					menu[collection.source] = [];
				}

				menu[collection.source].push(collection);
			}
		}

		let reactMenuItems = [];
		for (let key in menu) {
			reactMenuItems.push(<ListSubheader>{COLLECTIONS_SOURCE_MAP[key]}</ListSubheader>);

			for (let collection of menu[key]) {
				reactMenuItems.push(<MenuItem value={collection.collection}>{collection.label}</MenuItem>);
			}
		}

		return reactMenuItems;
	}

	return (
		<MDBox
			display="flex"
			flexDirection="column"
			style={{
				height: "100%",
				width: "100%"
			}}
		>
			{/* Up pannel */}
			<MDBox flex="1">
				<MDBox mb={4}>
					<MDTypography variant="h4">
						{i18n.t("SETTINGS.CHARTS.NEW.firstGeneralConfig")}
					</MDTypography>
				</MDBox>
				<MDBox display="flex" flex="1">
					{/* Data source */}
					<MDBox flex="1" mr={1}>
						<MDTypography variant="h6">
							{i18n.t("SETTINGS.CHARTS.NEW.dataSourceSelection")}
							<span className="mandatoryField">*</span>
						</MDTypography>
						<MDBox>
							<FormControl fullWidth>
								<Select
									labelId="select-label"
									id="select"
									value={request.collection}
									onChange={e => {
										let collection = collections.find(c => c.collection === e.target.value);
										updateCollection(collection);
									}}
									renderValue={key => {
										let dictionaryCollection = collections.find(
											c => c.collection === key
										)?.dictionary;
										let collectionObject = dictionaryCollection[key];
										let collectionName = collectionObject.label.fr;
										let source = COLLECTIONS_SOURCE_MAP[collectionObject.source];
										return (
											<MDBox>
												<MDBox>
													<MDTypography variant="h6">{collectionName}</MDTypography>
												</MDBox>
												<MDBox>
													<MDTypography variant="body2">{source}</MDTypography>
												</MDBox>
											</MDBox>
										);
									}}
								>
									{mapMenuCollection(collections)}
								</Select>
							</FormControl>
						</MDBox>
					</MDBox>
					{/* Compute method choice */}
					<MDBox flex="1" ml={1}>
						<MDTypography variant="h6">
							{i18n.t("SETTINGS.CHARTS.NEW.computeMethodSelection")}
							<span className="mandatoryField">*</span>
						</MDTypography>
						<MDBox>
							<FormControl fullWidth>
								<Select
									disabled={!request.collection}
									labelId="select-label"
									id="select"
									value={request.computeMethod}
									onChange={e => {
										let method = C.COMPUTE_METHOD.find(m => m.code === e.target.value);
										updateMethod(method);
									}}
								>
									{C.COMPUTE_METHOD.map((method, index) => (
										<MenuItem key={index} value={method.code}>
											<MDBox>
												<MDBox display="flex" alignItems="center">
													<MDBox display="flex" alignItems="center" mr={1}>
														<Icon>{method.icon}</Icon>
													</MDBox>
													<MDTypography variant="h6">{i18n.t(method.label)}</MDTypography>
												</MDBox>
												<MDTypography variant="body2">{i18n.t(method.description)}</MDTypography>
											</MDBox>
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</MDBox>
					</MDBox>
				</MDBox>
			</MDBox>
			<MDBox>
				<Divider />
			</MDBox>
			{/* Down pannel */}
			<MDBox flex="1" display="flex" flexDirection="row">
				{/*
				 * Fields selection
				 */}
				<MDBox flex="1" mr={1}>
					<MDBox mb={4}>
						<MDTypography variant="h4">
							{i18n.t("SETTINGS.CHARTS.NEW.secondDetailConfig")}
						</MDTypography>
					</MDBox>
					{/*
					 * Attribute field
					 * For all charts except count
					 */}
					{request.computeMethod !== "COUNT" && (
						<FieldInput
							required
							info={i18n.t("SETTINGS.CHARTS.NEW.computeAttributeInfo")}
							getLabelFromDictionary={getLabelFromDictionary}
							request={request}
							disabled={!request.computeMethod}
							title={i18n.t("SETTINGS.CHARTS.NEW.computeAttribute")}
							field="attribute"
							dictionary={dictionary}
							updateRequest={updateRequest}
						/>
					)}
					{/*
					 * Filter date field
					 * For all charts
					 */}
					<FieldInput
						info={i18n.t("SETTINGS.CHARTS.NEW.filterDateAttributeInfo")}
						getLabelFromDictionary={getLabelFromDictionary}
						request={request}
						disabled={!request.computeMethod}
						title={i18n.t("SETTINGS.CHARTS.NEW.filterDateAttribute")}
						field="filterDateAttribute"
						dictionary={parseTypeDictionary(["date", "datetime"], dictionary)}
						updateRequest={updateRequest}
					/>
					{/*
					 * Group field
					 * For oneDimension / proportion charts
					 */}
					{(["oneDimension", "proportion"].includes(chart.type) ||
						["sortedList"].includes(chart.display.subType)) && (
						<FieldInput
							required={chart.display.subType !== "sortedList"}
							info={i18n.t("SETTINGS.CHARTS.NEW.groupByInfo")}
							getLabelFromDictionary={getLabelFromDictionary}
							request={request}
							disabled={!request.computeMethod}
							title={i18n.t("SETTINGS.CHARTS.NEW.groupBy")}
							field="group"
							dictionary={dictionary}
							updateRequest={updateRequest}
						/>
					)}
					{/*
					 * Col field
					 * For crossTable
					 */}
					{["crossTable"].includes(chart.type) && (
						<FieldInput
							required
							info={i18n.t("SETTINGS.CHARTS.NEW.abcisseSelectionInfo")}
							getLabelFromDictionary={getLabelFromDictionary}
							request={request}
							disabled={!request.computeMethod}
							title={i18n.t("SETTINGS.CHARTS.NEW.abcisseSelection")}
							field="col"
							dictionary={dictionary}
							updateRequest={updateRequest}
						/>
					)}
					{/*
					 * Bining fields
					 * For timeSeries
					 */}
					{["timeSeries"].includes(chart.type) && (
						<>
							<FieldInput
								required
								info={i18n.t("SETTINGS.CHARTS.NEW.dateAttributeSelectionInfo")}
								getLabelFromDictionary={getLabelFromDictionary}
								request={request}
								disabled={!request.computeMethod}
								title={i18n.t("SETTINGS.CHARTS.NEW.dateAttributeSelection")}
								field="dateAttribute"
								dictionary={parseTypeDictionary(["datetime", "date"], dictionary)}
								updateRequest={updateRequest}
							/>
							<MDBox mt={2}>
								<MDBox display="flex" alignItems="center">
									<MDTypography variant="h6">
										{i18n.t("SETTINGS.CHARTS.NEW.binningSelection")}
										<span className="mandatoryField">*</span>
									</MDTypography>
									<MDBox ml={1} display="flex" alignItems="center">
										<Tooltip
											title={i18n.t("SETTINGS.CHARTS.NEW.binningSelectionInfo")}
											placement="top"
										>
											<Icon>info</Icon>
										</Tooltip>
									</MDBox>
								</MDBox>
								<MDBox display="flex">
									<MDBox
										borderRadius="lg"
										display="flex"
										alignItems="center"
										bgColor="light"
										sx={{ width: "100%", mr: 1, p: 0.5 }}
										style={{
											border: "1px solid #d2d6da"
										}}
									>
										{!binning.length ? (
											<MDBox ml={1}>
												<MDTypography fontSize="small">
													{i18n.t("SETTINGS.CHARTS.NEW.noBinning")}
												</MDTypography>
											</MDBox>
										) : (
											binning.map((b, index) => {
												return (
													<Chip
														sx={{ mr: 0.5 }}
														label={
															<MDBox display="flex" alignItems="center">
																<MDBox>
																	{i18n.t(C.MONGODB_BINNING_FORMAT.find(f => f.code === b)?.label)}
																</MDBox>
																<IconButton
																	size="small"
																	onClick={() => {
																		removeBinning(index);
																	}}
																>
																	<Icon>close</Icon>
																</IconButton>
															</MDBox>
														}
													/>
												);
											})
										)}
									</MDBox>
									<MDButton
										disabled={binning.length > 5 || !request.computeMethod}
										variant="gradient"
										color="info"
										onClick={e => {
											setBinningMenuEl(e.target);
										}}
										sx={{ mr: 1 }}
									>
										{i18n.t("SETTINGS.add")}
									</MDButton>
									<Menu
										open={Boolean(binningMenuEl)}
										anchorEl={binningMenuEl}
										onClose={() => setBinningMenuEl(null)}
									>
										{C.MONGODB_BINNING_FORMAT.map((b, i) => {
											return (
												<MenuItem
													key={i}
													onClick={() => {
														updateBinning(b.code);
														setBinningMenuEl(null);
													}}
												>
													{i18n.t(b.label)}
												</MenuItem>
											);
										})}
									</Menu>
								</MDBox>
							</MDBox>
						</>
					)}
					{/*
					 * Row field
					 * For crossTable / timeSeries
					 */}
					{["crossTable", "timeSeries"].includes(chart.type) && (
						<FieldInput
							info={i18n.t("SETTINGS.CHARTS.NEW.ordinateSelectionInfo")}
							getLabelFromDictionary={getLabelFromDictionary}
							request={request}
							disabled={!request.computeMethod}
							title={i18n.t("SETTINGS.CHARTS.NEW.ordinateSelection")}
							field="row"
							dictionary={dictionary}
							updateRequest={updateRequest}
						/>
					)}
				</MDBox>
				{/*
				 * Filtres
				 */}
				<MDBox flex="1" ml={1}>
					<MDBox mb={4}>
						<MDTypography variant="h4">
							{i18n.t("SETTINGS.CHARTS.NEW.filtersSelection")}
						</MDTypography>
					</MDBox>
					<FiltersTable
						disabled={!isStepValid}
						filtersArray={filtersArray}
						handleRemoveFilter={handleRemoveFilter}
						onChangeValue={onChangeValue}
						handleAddFilter={handleAddFilter}
						dictionary={dictionary}
					/>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default DataChoiceStep;
