/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Page settings
 *
 * #######################################################@
 */
import "./style.css";

import {
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Icon,
	IconButton,
	InputLabel,
	MenuItem,
	Select
} from "@mui/material";
import { useEffect, useState } from "react";
import MDButton from "components/Basics/MDButton";
import MDBox from "components/Basics/MDBox";
import MDInput from "components/Basics/MDInput";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";
import { useDispatch, useSelector } from "react-redux";
import lod_ from "lodash";
import { updatePermissions } from "redux-react/reducers/permissionsReducer";
import DictionaryMenu from "../filters/DictionaryMenu";
import C from "../charts/steps/constants";
import IconAccordion from "../charts/steps/components/IconAccordion";
import SettingsActions from "redux-react/actions/settingsActions";
import ProfileActions from "redux-react/actions/profileActions";

/**
 * Component to display a role in the list
 */
const RolesBox = ({ type, name, code, rolesList = [], onChange }) => {
	const isInArray = arr => {
		return arr.includes(code);
	};

	return (
		<MenuItem onClick={() => onChange(code)}>
			<MDBox display="flex" alignItems="center" justifyContent="start">
				<Checkbox checked={isInArray(rolesList)} />
				<MDBox display="flex" flexDirection="column">
					<MDTypography variant="body">{name}</MDTypography>
					{/* <MDTypography variant="caption">({i18n.t(`SETTINGS.${type}`)})</MDTypography> */}
				</MDBox>
			</MDBox>
		</MenuItem>
	);
};

export default function AddPageDialog({
	open,
	handleCloseDialog,
	handleSave,
	rank,
	dictionary,
	filtersList = {}
}) {
	const profile = useSelector(state => state.profile);
	const dispatch = useDispatch();
	/* Page attributes */
	const [name, setName] = useState(null);
	const [type, setType] = useState("route");
	const [icon, setIcon] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);

	const [collection, setCollection] = useState(Object.keys(dictionary));

	const [routeDictLabel, setRouteDictLabel] = useState(null);

	const [form, setForm] = useState({
		active: true,
		collectionDefault: null,
		pageLabel: null
	});

	const [filterCode, setFilterCode] = useState(null);
	// -> Roles that will be displayed in the list to be selected
	const [defaultRolesList, setDefaultRolesList] = useState([]);
	// -> Roles that will be selected by the user
	const [rolesList, setRolesList] = useState([]);

	const disabledForType = type => {
		switch (type) {
			case "route":
				if (!name) {
					return true;
				}
				break;
			case "form":
				if (!form.collectionDefault || form.collectionDefault === "none") {
					return true;
				}
				break;
			case "title":
				if (!name) {
					return true;
				}
				break;
			case "divider":
				return false;
			default:
				return false;
		}

		return false;
	};

	const isDisabled = disabledForType(type);
	/**
	 * Close dialog
	 */
	function close() {
		handleCloseDialog();
		setName(null);
		setType("route");
		setFilterCode(null);
		setRolesList([]);
		setRouteDictLabel("");
		setForm({
			active: true,
			collectionDefault: null,
			pageLabel: null
		});
	}
	/**
	 * Create the page permission
	 * format be like : "read:page_[type]_[name]"
	 * For dividers, need to create a random ID because they have no name
	 */
	function createPagePermission(type, routeName, list) {
		// If user didn't select any role, return null -> We dont want permission on this page
		if (!list || lod_.isEmpty(list)) return null;
		// If the page is a divider, create a random ID for it
		if (type === "divider") {
			let rdmID = Math.random().toString(36).substring(7);
			return `read:page_${type}_${rdmID}`;
		}
		// Else, return the permission
		return `read:page_${type}_${routeName}`;
	}
	/**
	 * Submit the dialog, create the page
	 */
	function sumbit() {
		let returnObject = {};
		// 1- Create the page permission
		let pagePermission = createPagePermission(
			type,
			name?.toLowerCase().trim().replaceAll(" ", "-"),
			rolesList
		);
		// 2- Map datas to the object
		switch (type) {
			// When we create a "route" => A page
			case "route":
				returnObject = {
					rank,
					type: "route",
					name,
					route: `/${name.toLowerCase().trim().replaceAll(" ", "-")}`,
					icon,
					filter: filterCode,
					permissions: pagePermission ? [pagePermission] : []
				};
				break;
			case "form":
				returnObject = {
					rank,
					type: "route",
					name,
					route: `/${name.toLowerCase().trim().replaceAll(" ", "-")}`,
					icon,
					form,
					filter: filterCode
				};
				break;
			// When we create a title
			case "title":
				returnObject = {
					rank,
					type,
					name,
					permissions: pagePermission ? [pagePermission] : []
				};
				break;
			// When we create a divider
			case "divider":
				returnObject = {
					rank,
					type,
					permissions: pagePermission ? [pagePermission] : []
				};
				break;
			default:
				break;
		}

		// 3- If the user set some permissions, need to save them
		if (!lod_.isEmpty(rolesList)) {
			// 3.1- First, create the custom permissions for the page
			// 3.2- Then, get the new permissions list and update the profile

			/* Handler when getting new permissions */
			const getCustomPermissionsSuccess = res => {
				// Update store with the new permissions
				dispatch(updatePermissions(res.permissions));
				handleSave(returnObject);
				close();
			};
			/* Handler when saving permissions */
			const createCustomPermissionSuccess = res => {
				// Get the new permissions list
				dispatch(
					ProfileActions.getCustomPermissions(
						profile.assistantID,
						profile.role,
						getCustomPermissionsSuccess
					)
				);
			};
			// Save the custom permissions for the page, for all the selected roles
			dispatch(
				ProfileActions.createCustomPermission(
					profile.assistantID,
					rolesList,
					process.env.REACT_APP_APPNAME,
					pagePermission,
					createCustomPermissionSuccess
				)
			);
		} else {
			handleSave(returnObject);
			close();
		}
	}
	/**
	 * When user select / deselect a role in the list
	 */
	function updateRolesList(newRole) {
		if (rolesList.includes(newRole)) {
			setRolesList(rolesList.filter(role => role !== newRole));
		} else {
			setRolesList([...rolesList, newRole]);
		}
	}
	/**
	 * Load default roles list (from KM)
	 */
	function loadRoles() {
		dispatch(
			SettingsActions.getRoles(res => {
				// Sort by type
				res.roles.sort((a, b) => {
					if (a.type < b.type) return -1;
					if (a.type > b.type) return 1;
					return 0;
				});
				setDefaultRolesList(res.roles);
			})
		);
	}

	const addItem = item => {
		let route = item.replaceAll(".", ".items.");
		setForm({ ...form, routeDictionary: route });
		let itemLabel = lod_.get(dictionary, route)?.label?.fr;
		setAnchorEl(null);
		setRouteDictLabel(itemLabel);
	};

	/**
	 * Load roles list on start
	 */
	useEffect(() => {
		loadRoles();
	}, []);

	return (
		<Dialog fullWidth maxWidth="lg" open={open} onClose={close}>
			<MDBox display="flex">
				<MDBox flex="2">
					<DialogTitle>{i18n.t("SETTINGS.PAGES.addPage")}</DialogTitle>
					<DialogContent>
						<MDBox mt={1}>
							<FormControl fullWidth>
								<InputLabel
									style={{
										padding: "12px !important"
									}}
									id="select-type"
								>
									{i18n.t("SETTINGS.PAGES.type")}
								</InputLabel>
								<Select
									labelId="select-type"
									value={type}
									label={i18n.t("SETTINGS.PAGES.type")}
									onChange={e => setType(e.target.value)}
								>
									<MenuItem value="route">{i18n.t("SETTINGS.PAGES.route")}</MenuItem>
									<MenuItem value="form">Form</MenuItem>
									<MenuItem value="title">{i18n.t("SETTINGS.PAGES.title")}</MenuItem>
									<MenuItem value="divider">{i18n.t("SETTINGS.PAGES.divider")}</MenuItem>
								</Select>
							</FormControl>
						</MDBox>

						{type !== "divider" && (
							<MDBox mt={1}>
								<MDInput
									className="dialogInput"
									label={i18n.t("SETTINGS.PAGES.name")}
									onChange={e => setName(e.target.value)}
								/>
							</MDBox>
						)}
						{(type === "route" || type === "form") && (
							<>
								<MDBox display="flex" alignItems="flex-start " flexDirection="column">
									<MDBox mt={1}>
										{/* Icon choice */}
										<IconAccordion
											open
											forceOpen
											title={i18n.t("SETTINGS.CHARTS.NEW.iconAvailableIcons")}
											content={C.PAGE_ICONS_LIST.map((ic, index) => {
												return (
													<IconButton
														key={index}
														onClick={() => {
															setIcon(ic);
														}}
													>
														<Icon color={icon === ic ? "info" : ""} fontSize="large">
															{ic}
														</Icon>
													</IconButton>
												);
											})}
											actions={
												<MDButton onClick={() => setIcon(null)}>
													<Icon>close</Icon>&nbsp;{i18n.t("SETTINGS.delete")}
												</MDButton>
											}
										/>
									</MDBox>
								</MDBox>

								<MDBox mt={2}>
									<MDTypography variant="h6">{i18n.t("SETTINGS.PAGES.filter")}</MDTypography>
								</MDBox>

								<MDBox mt={1}>
									<FormControl fullWidth>
										<InputLabel id="select-type">{i18n.t("SETTINGS.PAGES.filter")}</InputLabel>
										<Select
											labelId="select-type"
											value={filterCode || "none"}
											label={i18n.t("SETTINGS.PAGES.filter")}
											onChange={e => {
												if (e.target.value === "none") setFilterCode(null);
												else setFilterCode(e.target.value);
											}}
										>
											<MenuItem value="none">{i18n.t("SETTINGS.PAGES.none")}</MenuItem>
											{filtersList &&
												filtersList.map(filter => {
													return <MenuItem value={filter.code}>{filter.name}</MenuItem>;
												})}
										</Select>
									</FormControl>
								</MDBox>

								{type === "form" && (
									<>
										<MDBox mt={2}>
											<MDTypography variant="h6">{i18n.t("SETTINGS.PAGES.form")}</MDTypography>
										</MDBox>

										<MDBox mt={1}>
											<FormControl fullWidth>
												<InputLabel id="select-label">Collection</InputLabel>
												<Select
													labelId="select-collection-form"
													value={form.collectionDefault || "none"}
													label="Collection"
													onChange={e => {
														setForm({
															...form,
															collectionDefault: e.target.value
														});
													}}
												>
													<MenuItem value="none">Aucune</MenuItem>
													{collection &&
														collection.map(filter => {
															return <MenuItem value={filter}>{filter}</MenuItem>;
														})}
												</Select>
											</FormControl>
										</MDBox>

										<MDBox mt={2}>
											<MDInput
												value={form.pageLabel}
												className="dialogInput"
												label="Label de page"
												onChange={e =>
													setForm({
														...form,
														pageLabel: e.target.value
													})
												}
											/>
										</MDBox>

										<MDBox mt={2}>
											<MDTypography variant="h6">Objet source</MDTypography>
										</MDBox>
										<MDBox mt={2}>
											<MDBox display="flex" alignItems="center">
												<MDButton
													variant="gradient"
													color="info"
													onClick={e => setAnchorEl(e.target)}
												>
													{i18n.t("SETTINGS.add")}
												</MDButton>
												<DictionaryMenu
													levelLayer
													dictionary={dictionary}
													anchorEl={anchorEl}
													handleInsertText={addItem}
													handleClose={() => setAnchorEl(null)}
												/>
												<MDBox display="flex" ml={1}>
													<MDTypography variant="body2">
														{routeDictLabel ?? "Aucune selection"}
													</MDTypography>
												</MDBox>
											</MDBox>
										</MDBox>
									</>
								)}
							</>
						)}
					</DialogContent>
					<DialogActions>
						<MDButton variant="outlined" color="dark" onClick={close}>
							{i18n.t("SETTINGS.cancel")}
						</MDButton>
						<MDButton disabled={isDisabled} variant="contained" color="dark" onClick={sumbit}>
							{i18n.t("SETTINGS.add")}
						</MDButton>
					</DialogActions>
				</MDBox>
				<MDBox ml={2} pr={4} flex="1">
					<DialogTitle>{i18n.t("SETTINGS.PAGES.visibleBy")}</DialogTitle>
					<DialogContent>
						{defaultRolesList &&
							defaultRolesList.map((perm, index) => {
								return (
									<RolesBox
										key={index}
										type={perm.type}
										name={perm.name}
										code={perm.code}
										rolesList={rolesList}
										onChange={perm => {
											updateRolesList(perm);
										}}
									/>
								);
							})}
					</DialogContent>
				</MDBox>
			</MDBox>
		</Dialog>
	);
}
