/* eslint-disable consistent-return */
import MDAlert from "components/Basics/MDAlert";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { selectNotifications } from "redux-react/reducers/notificationsReducer";
import { selectNotificationsList, selectCurrentProfile } from "redux-react/reducers/profileReducer";
import { selectSnackBar, hide } from "redux-react/reducers/snackBarReducer";
/**
 * #######################################################@
 *
 * SnackBar component
 *
 * Used to display a message in a snackbar by the store
 *
 * #######################################################@
 */
import { Snackbar, Alert, Icon, Slide } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const SnackBarMessage = () => {
	const dispatch = useDispatch();
	const profile = useSelector(selectCurrentProfile);
	const snackBar = useSelector(selectSnackBar);
	const notifications = useSelector(selectNotifications);
	const notificationsList = useSelector(selectNotificationsList);

	const handleClose = () => {
		dispatch(hide());
	};

	const [alertContent, setAlertContent] = useState("");

	const [showAlert, setShowAlert] = useState(false);

	const [oldLength, setOldLength] = useState({});

	useEffect(() => {
		if (!notificationsList.length) {
			setOldLength({
				...oldLength,
				[profile.assistantID]: 0
			});
			return;
		}

		if (notificationsList.length > (oldLength[profile.assistantID] || 0)) {
			setOldLength({
				...oldLength,
				[profile.assistantID]: notificationsList.length
			});
			setAlertContent(notificationsList[notificationsList.length - 1].message);
			setShowAlert(() => {
				return false;
			});
			setShowAlert(() => {
				return true;
			});

			const timer = setTimeout(() => {
				setShowAlert(() => {
					return false;
				});
			}, 3000);

			// return () => clearTimeout(timer);
		}
	}, [notifications, dispatch]);

	return (
		<>
			{/*
			 * Notifications
			 */}
			<div className="notificationsContainer">
				<Slide in={showAlert} direction="down">
					<div>
						<MDAlert>
							<Icon fontSize="small">notifications</Icon>
							<MDTypography variant="body2" color="white">
								{alertContent ?? "Nouvelle notification !"}
							</MDTypography>
						</MDAlert>
					</div>
				</Slide>
			</div>
			{/*
			 * SnackBar
			 */}
			<Snackbar
				open={snackBar.open ?? false}
				autoHideDuration={snackBar.validation ? null : 4000}
				onClose={snackBar.validation ? null : handleClose}
			>
				<Alert
					severity={snackBar.type ?? "info"}
					style={{
						display: "flex",
						alignItems: "center"
					}}
				>
					<MDBox display="flex" alignItems="center">
						{snackBar.message ?? ""}
						{snackBar.validation ? snackBar.validation : null}
					</MDBox>
				</Alert>
			</Snackbar>
		</>
	);
};

export default SnackBarMessage;
