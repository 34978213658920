/* eslint-disable no-constant-condition */
/* eslint-disable no-await-in-loop */
import axios from "axios";
// import { clear, coldError, errorMsg } from "redux-react/reducers/snackMsgsReducers";

import { setError } from "redux-react/reducers/errorReducer";
import { display } from "redux-react/reducers/snackBarReducer";
import traceError from "./utils";

function validateArgument(argument, backArgument, nullable) {
	if (argument === backArgument) {
		return true;
	}

	if (!argument && nullable) {
		return true;
	}

	return false;
}

const apiMiddleware =
	({ dispatch, getState }) =>
	next =>
	action => {
		if (!action) {
			return;
		}
		if (Array.isArray(action)) {
			action.forEach(obj => obj && dispatch(obj));
			return;
		}
		next(action);

		if (!action?.payload?.requestUrl) return;

		let {
			isCustomUrl,
			requestUrl: url,
			method,
			data,
			onLoading,
			onSuccess,
			onFailure,
			headers,
			withCredentials,
			stream,
			/* Special stream */
			onBeforeStream,
			onDataStream,
			onEndStream,
			onAfterStream
		} = action.payload;
		const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

		// axios default configs
		axios.defaults.baseURL = process.env.REACT_APP_AMBACK || "";
		axios.defaults.headers.common["Content-Type"] = "application/json";

		const { user, profile } = getState();

		if (user && !headers && !isCustomUrl) {
			headers = {
				Authorization: `Bearer ${user.token}`
			};
		}
		/**
		 * Set assistantID in headers to verify response
		 */
		headers.AssistantID = profile.assistantID;

		if (onLoading) {
			dispatch(onLoading(true));
		}

		if (stream) {
			fetch(url, {
				method,
				headers: {
					...headers,
					stream: true,
					"Content-Type": "application/json"
				},
				body: JSON.stringify(data)
			})
				.then(response => {
					const reader = response.body.getReader();

					const processStream = async () => {
						dispatch(onBeforeStream());

						while (true) {
							const { done, value } = await reader.read();
							if (done) {
								dispatch(
									onEndStream({
										type: "end"
									})
								);
								break;
							}
							const chunk = new TextDecoder().decode(value);
							dispatch(
								onDataStream({
									type: "chunk",
									chunk
								})
							);
						}

						dispatch(onAfterStream());
					};

					processStream();
				})
				.catch(error => {
					if (typeof onFailure === "function") {
						let errMsg;
						try {
							errMsg = JSON.stringify(data);
						} catch (error) {
							errMsg = ">cannot stringify the data<";
						}
						traceError(
							user,
							headers,
							`apiMiddleware-${error.message}, url: ${url}, data: ${errMsg}`
						);
						// LEGACY CODE : LOOK FOR ERROR
						if (error.message === "Network Error") {
							dispatch(setError("networkError"));
						} else {
							dispatch(onFailure(error));
						}
					}
				})
				.then(() => {
					if (onLoading) {
						dispatch(onLoading(false));
					}
				});
		} else {
			axios
				.request({
					url,
					method,
					headers,
					[dataOrParams]: data,
					withCredentials
				})
				.then(({ data }) => {
					/**
					 * Verify response format, must be :
					 *
					 * {
					 *  header: {},
					 *  payload: {}
					 * }
					 */
					if (data.header && data.payload) {
						/**
						 * Verify response header
						 * - userEmail is the same
						 * - assistantID is the same
						 * - application is the same
						 */
						let isUser = validateArgument(user.userID, data.header.userEmail);
						let isAssistantID = validateArgument(
							profile.assistantID,
							data.header.assistantID,
							true
						);
						let isApplication = validateArgument(
							process.env.REACT_APP_APPNAME,
							data.header.application
						);

						if (isUser && isAssistantID && isApplication) {
							dispatch(onSuccess(data.payload));
						} else {
							dispatch(
								display({
									message: "Request received unauthorized",
									type: "error"
								})
							);
						}
					} else {
						dispatch(
							display({
								message: "Request received bad format",
								type: "error"
							})
						);
					}
				})
				.catch(error => {
					if (typeof onFailure === "function") {
						let errMsg;
						try {
							errMsg = JSON.stringify(data);
						} catch (error) {
							errMsg = ">cannot stringify the data<";
						}
						traceError(
							user,
							headers,
							`apiMiddleware-${error.message}, url: ${url}, data: ${errMsg}`
						);
						// LEGACY CODE : LOOK FOR ERROR
						if (error.message === "Network Error") {
							dispatch(setError("networkError"));
						} else {
							dispatch(onFailure(error));
						}
					}
				})
				.then(() => {
					if (onLoading) {
						dispatch(onLoading(false));
					}
				});
		}
	};

export default apiMiddleware;
