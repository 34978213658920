/* eslint-disable no-underscore-dangle */
/* eslint-disable no-self-compare */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default-member */
/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import "./style.css";

import DashboardLayout from "components/Advanced/LayoutContainers/DashboardLayout";

import { CircularProgress, Divider, Icon } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import ConfirmDialog from "components/Custom/Dialogs/ConfirmDialog";
import DashboardNavbar from "components/Advanced/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import PagesDataTable from "components/Custom/Tables/PagesDataTable";
import MDButton from "components/Basics/MDButton";
import i18n from "i18n";
import AddPageDialog from "./AddPageDialog";
import EditPageDialog from "./EditPageDialog";

import ChartsActions from "redux-react/actions/chartsActions";
import ProfileActions from "redux-react/actions/profileActions";
import SettingsActions from "redux-react/actions/settingsActions";
import { display } from "redux-react/reducers/snackBarReducer";

export default function SettingsPages({ route }) {
	const { profile } = useSelector(state => state);
	const dispatch = useDispatch();

	const [paginedList, setPaginedList] = useState(null);
	const [filtersList, setFiltersList] = useState(null);

	const [addNewPage, setAddNewPage] = useState(false);
	const [tableLoading, setTableLoading] = useState(false);
	const [editPage, setEditPage] = useState({ open: false });
	// Confirm dialog
	const [confirmDialog, setConfirmDialog] = useState({
		open: false,
		title: "",
		content: "",
		handleConfirm: () => {}
	});
	/**
	 * Load pagined list
	 */
	function loadPaginedList() {
		const onSuccess = res => {
			setPaginedList(res.paginedList[0]);
			setTableLoading(false);
		};

		dispatch(ChartsActions.getPaginedList(profile.assistantID, route, onSuccess));
		// get filters
		dispatch(
			SettingsActions.getSettings(profile.assistantID, "filters", res => {
				setFiltersList(res.documents);
			})
		);
	}
	/**
	 * Save new page
	 */
	function handleSaveNewPage(page) {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("SETTINGS.PAGES.SUCCESS.add"),
					type: "success"
				})
			);
			loadPaginedList();
			dispatch(ProfileActions.updateMenu(profile.assistantID));
		};
		dispatch(SettingsActions.newSetting(profile.assistantID, "page", page, onSuccess));
	}
	/**
	 * Handle page reorder
	 * @param {Number} from
	 * @param {Number} to
	 */
	function handleReorderPage(from, to) {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("SETTINGS.PAGES.SUCCESS.edit"),
					type: "success"
				})
			);

			loadPaginedList();
			dispatch(ProfileActions.updateMenu(profile.assistantID));
		};
		dispatch(SettingsActions.reorderPage(profile.assistantID, { from, to }, onSuccess));
	}
	/**
	 * Handle edit page
	 */
	function actionEditHandle(page) {
		const onSuccess = res => {
			dispatch(
				display({
					message: i18n.t("SETTINGS.PAGES.SUCCESS.edit"),
					type: "success"
				})
			);
			loadPaginedList();
			dispatch(ProfileActions.updateMenu(profile.assistantID));
		};
		dispatch(SettingsActions.editSetting(profile.assistantID, "page", page, onSuccess));
	}
	/**
	 * Handle delete page
	 */
	function actionDeleteHandle(page) {
		const handleConfirmDialog = () => {
			setConfirmDialog({
				open: false
			});
			const onSuccess = res => {
				dispatch(
					display({
						message: i18n.t("SETTINGS.PAGES.SUCCESS.delete"),
						type: "success"
					})
				);
				loadPaginedList();
				dispatch(ProfileActions.updateMenu(profile.assistantID));
			};
			dispatch(
				SettingsActions.deleteSetting(profile.assistantID, "page", { _id: page._id }, onSuccess)
			);
		};

		setConfirmDialog({
			open: true,
			title: `${i18n.t("SETTINGS.delete")} : ${i18n.t(`SETTINGS.PAGES.${page.type}`)} ${
				page.name ? `"${page.name}"` : ""
			}`,
			content: `${i18n.t("SETTINGS.deleteConfirmation")} : ${i18n.t(
				`SETTINGS.PAGES.${page.type}`
			)} ${page.name ? `"${page.name}"` : ""} ?`,
			handleConfirm: handleConfirmDialog
		});
	}
	/**
	 * When user create a page, set page's rank to the next rank
	 */
	function getNextRank(rows) {
		const maxRank = rows.reduce((acc, row) => {
			if (row.rank > acc) {
				return row.rank;
			}
			return acc;
		}, 0);
		return maxRank + 1;
	}

	/**
	 * Load pages list on mount
	 */
	useEffect(() => {
		setPaginedList(null);
		loadPaginedList();
	}, [profile.selectedAssistant.assistantID, route]);

	return (
		<DashboardLayout>
			<DashboardNavbar
				filters={[
					<MDButton
						style={{ height: "100%", marginRight: "0.75rem" }}
						variant="contained"
						color="info"
						onClick={() => setAddNewPage(true)}
					>
						<Icon>add</Icon>&nbsp;{i18n.t("SETTINGS.PAGES.addPage")}
					</MDButton>
				]}
			/>
			<Divider />
			{paginedList && (
				<div
					style={{
						position: "relative",
						width: "100%",
						height: "100%"
					}}
				>
					{tableLoading && (
						<div
							style={{
								width: "100%",
								height: "100%",
								backgroundColor: "rgba(0,0,0,0.2)",
								position: "absolute",
								top: 0,
								left: 0,
								zIndex: 100,
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								borderRadius: 10
							}}
						>
							<CircularProgress color="info" />
						</div>
					)}
					<PagesDataTable
						draggable
						table={paginedList.data}
						display={paginedList.request.attributesDisplay}
						actionEditHandle={page => setEditPage({ ...page, open: true })}
						actionDeleteHandle={actionDeleteHandle}
						dragHandler={({ from, to }) => {
							if (from === to) return;
							setTableLoading(true);
							handleReorderPage(from, to);
						}}
					/>
				</div>
			)}
			{paginedList && filtersList && (
				<AddPageDialog
					dictionary={paginedList.dictionary}
					permissionsList={paginedList.permissionsList}
					open={addNewPage}
					handleCloseDialog={() => setAddNewPage(false)}
					handleSave={handleSaveNewPage}
					rank={getNextRank(paginedList.data.rows)}
					filtersList={filtersList}
				/>
			)}
			{paginedList && filtersList && (
				<EditPageDialog
					dictionary={paginedList.dictionary}
					permissionsList={paginedList.permissionsList}
					open={editPage.open}
					handleCloseDialog={() => setEditPage({ open: false })}
					handleSave={actionEditHandle}
					page={editPage}
					filtersList={filtersList}
				/>
			)}
			<ConfirmDialog
				open={confirmDialog.open}
				title={confirmDialog.title}
				content={confirmDialog.content}
				handleClose={() => {
					setConfirmDialog({
						open: false
					});
				}}
				handleConfirm={confirmDialog.handleConfirm}
			/>
		</DashboardLayout>
	);
}
