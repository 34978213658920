import axios from "axios";

/**
 * Send an error log (in data) to the backend
 * @param {user} user
 * @param {object} headers for the axios request
 * @param {string} data is the log message
 */
const traceError = async (user, headers, data) => {
	try {
		axios(process.env.REACT_APP_AMBACK + "/TRCERR", {
			method: "POST",
			headers,
			data: {
				agent: user?.email,
				app: process.env.REACT_APP_APPNAME,
				env: process.env.REACT_APP_ENV,
				ver: process.env.REACT_APP_VERSION,
				data
			}
		});
	} catch (e) {
		// console.log(`PIKA - Error: ${e.message}`);
	}
};

export default traceError;
