import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import { useEffect, useState } from "react";
import MDInput from "components/Basics/MDInput";
import i18n from "i18n";
import { selectAllSortedMenu } from "redux-react/reducers/profileReducer";

const FormDictionary = ({ submit }) => {
	const [name, setName] = useState("");
	const pages = useSelector(selectAllSortedMenu);
	const [selectedPages, setSelectedPages] = useState([]);

	useEffect(() => {
		if (selectedPages.length > 0 && name.trim() !== "") {
			submit({
				valid: true,
				data: {
					name,
					pages: selectedPages
				}
			});
		} else {
			submit({
				valid: false
			});
		}
	}, [name, selectedPages]);

	return (
		<MDBox>
			<MDTypography variant="h6">{i18n.t("SETTINGS.DICTIONARY.newDictionary")}</MDTypography>
			<MDBox display="flex" flexDirection="column" mt={1}>
				<MDBox>
					<MDInput
						className="dialogInput"
						value={name}
						onChange={e => setName(e.target.value)}
						label={
							<span>
								{i18n.t("SETTINGS.DICTIONARY.dictionaryName")}
								<span className="mandatoryField">*</span>
							</span>
						}
					/>
				</MDBox>
				<MDBox mt={1}>
					<FormControl fullWidth>
						<InputLabel id="select-pages-label">
							<span>
								{i18n.t("SETTINGS.DICTIONARY.dictionaryPages")}
								<span className="mandatoryField">*</span>
							</span>
						</InputLabel>
						<Select
							labelId="select-pages-label"
							id="select-pages"
							multiple
							value={selectedPages}
							onChange={e => setSelectedPages(e.target.value)}
							input={
								<OutlinedInput
									id="select-pages-chip"
									label={
										<span>
											{i18n.t("SETTINGS.DICTIONARY.dictionaryPages")}
											<span className="mandatoryField">*</span>
										</span>
									}
								/>
							}
							renderValue={selected => (
								<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
									{selected.map((page, index) => {
										let goodPage = pages.find(p => p.route === page);
										return <Chip key={index} label={goodPage.name} />;
									})}
								</Box>
							)}
						>
							{pages.map((page, index) => (
								<MenuItem key={index} value={page.route}>
									{page.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default FormDictionary;
