/**
 * #######################################################@
 *
 * Pages settings
 *
 * #######################################################@
 */
import { useEffect } from "react";
import MDBox from "components/Basics/MDBox";
import MDTypography from "components/Basics/MDTypography";
import i18n from "i18n";

export default function Step3Confirm({ validStep, skeleton }) {
	useEffect(() => {
		validStep();
	}, []);

	return (
		<MDBox
			sx={{ ml: 30, mr: 30 }}
			style={{
				height: "100%"
			}}
		>
			<MDBox
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				style={{
					height: "100%",
					whiteSpace: "break-spaces"
				}}
			>
				<MDTypography variant="h3">{`${skeleton?.values?.length || 0} ${i18n.t(
					"SOURCES.UPLOAD.datasWillBeAdd"
				)}`}</MDTypography>
			</MDBox>
		</MDBox>
	);
}
